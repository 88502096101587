import React, { useState, useEffect } from "react";
import { Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useInterval from 'use-interval';
import { useNavigate } from "react-router-dom";

import EmptyProfile from '../../../images/dashboard/empty-profile.png';
import AudioWaves from '../../../images/dashboard/audio-waves.png';

import { IoWarningSharp } from "react-icons/io5";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetCallStatus, GetNotes, UpdateNotes, CallStatusMessage, successMessage } from '../../../Redux/Slice';

function CallConnect({ showConnect, showNotes, notesClose, callClose, waitingList }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { AstrologerProfile, CallProcess, CallStatus, CallNotes, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("astrologer-auth-token");
    const orderId = localStorage.getItem("orderId");


    const [profileImage, setProfileImage] = useState(null);
    const [notes, setNotes] = useState(null);

    useInterval(() => {
        dispatch(GetCallStatus({ token, orderId }));
    }, 10000);

    const handleCallClose = () => {
        callClose(false);
        navigate('/work-desk');
        localStorage.removeItem("orderId");
        dispatch(CallStatusMessage({ CallStatus: '' }));
    }

    const handleUpdateNotes = () => {
        let callNotes = {
            notes: notes,
            userId: waitingList?.waiting?.userId,
            subuserId: waitingList?.waiting?.subuserId
        }
        dispatch(UpdateNotes({ token, callNotes }));
    }

    useEffect(() => {
        let userId = waitingList?.waiting?.userId;
        let subuserId = waitingList?.waiting?.subuserId;

        dispatch(GetNotes({ token, userId, subuserId }));
    }, [dispatch, token, waitingList]);

    useEffect(() => {
        if (success?.status === 200) {
            let userId = waitingList?.waiting?.userId;
            let subuserId = waitingList?.waiting?.subuserId;

            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "OK"
            }).then((result) => {
                if (result?.isConfirmed) {
                    dispatch(GetNotes({ token, userId, subuserId }));
                    dispatch(successMessage({ success: '' }));
                }
            })
        }
        else if (CallNotes?.status === 200) {
            setNotes(CallNotes?.data?.data?.notes);
        }
        else if (errors?.status === 400) {
            setNotes(null);
        }

        if (AstrologerProfile?.data?.data?.files.length > 0) {
            AstrologerProfile?.data?.data?.files.map((file) => {
                if (file.filefor === "profile") {
                    setProfileImage(file);
                }
            })
        }
        else {
            setProfileImage(null)
        }
    }, [CallNotes, success, errors, dispatch, token, AstrologerProfile]);

    const durationInSeconds = waitingList?.waiting?.duration || 0;
    const [time, setTime] = useState({ Hour: '', Minutes: '', Seconds: '' });

    useEffect(() => {
        let Hour = Math.floor(durationInSeconds / 3600);
        let remainingSecondsAfterHours = durationInSeconds % 3600;
        let Minutes = Math.floor(remainingSecondsAfterHours / 60);
        let Seconds = remainingSecondsAfterHours % 60;

        setTime({
            Hour: Hour,
            Minutes: Minutes,
            Seconds: Seconds
        });

    }, [durationInSeconds]);

    useEffect(() => {
        if (CallStatus?.data?.data?.callupdate != null && !(CallStatus?.data?.data?.iscompleted)) {
            const timer = setInterval(() => {
                // Update seconds
                setTime(prevTime => {
                    let updatedSeconds = prevTime.Seconds - 1;
                    let updatedMinutes = prevTime.Minutes;
                    let updatedHours = prevTime.Hour;

                    if (updatedSeconds < 0) {
                        updatedSeconds = 59;
                        updatedMinutes -= 1;
                    }
                    if (updatedMinutes < 0) {
                        updatedMinutes = 59;
                        updatedHours -= 1;
                    }
                    return {
                        Hour: updatedHours,
                        Minutes: updatedMinutes,
                        Seconds: updatedSeconds
                    };
                });
            }, 1000);

            return () => clearInterval(timer);
        }

        if (CallStatus?.data?.data?.iscompleted) {
            setTimeout(() => {
                handleCallClose();
            }, 15000)
        }
    }, [CallStatus]);

    const formatTime = (value) => {
        return value < 10 ? `0${value}` : value;
    };

    return (
        <>
            {
                showConnect || orderId ?
                    <>
                        <Col lg={5} sm={5} className={showConnect || orderId ? 'waiting-user-detail call-connect' : 'call-connect-none'}>
                            <Col lg={12} className="reload-aleart">
                                <p className="text-center text-danger">  Call is currently in process.  don't reload the page.</p>
                            </Col>
                            <Col lg={12} sm={12} className='d-flex justify-content-between'>
                                <Col lg={4} sm={4} className='d-flex flex-column align-items-center call-screen'>
                                    <div className='call-profile'>
                                        <img src={waitingList?.profile ? waitingList?.profile?.file : EmptyProfile} alt="profile" className='img-fluid' />
                                    </div>
                                    <h5 className='mt-3'>{waitingList?.waiting?.subuser?.name}</h5>
                                </Col>
                                <Col lg={2} sm={2} className='d-flex flex-column justify-content-center align-items-center'>
                                    <div className='call-wave'>
                                        <img src={AudioWaves} alt="audio" className='img-fluid' />
                                    </div>
                                    {CallStatus?.data?.data?.iscompleted ? null :
                                        <div className="time-count">
                                            {formatTime(time?.Hour)}:{formatTime(time?.Minutes)}:{formatTime(time?.Seconds)}
                                        </div>
                                    }
                                </Col>
                                <Col lg={4} sm={4} className='d-flex flex-column align-items-center call-screen'>
                                    <div className='call-profile'>
                                        <img src={profileImage ? profileImage?.file : EmptyProfile} alt="profile" className='img-fluid' />
                                    </div>
                                    <h5 className='mt-3'>{AstrologerProfile?.data?.data?.astrologer?.name}</h5>
                                </Col>
                            </Col>
                            <div className='mt-3 call-connecting-msg'>
                                <h3>{CallStatus?.data?.data?.callupdate === null ? "Connecting..." : CallStatus?.data?.data?.callupdate}</h3>
                            </div>
                            <Col lg={12} className={`d-flex ${CallStatus?.data?.data?.iscompleted ? 'justify-content-between' : 'justify-content-end'} call-btn`}>
                                {CallStatus?.data?.data?.iscompleted ?
                                    <div className='notes-btn'>
                                        <button className='notes-save-btn bg-red' onClick={() => handleCallClose()}>Close</button>
                                    </div>
                                    : null
                                }
                                {/* <div className='notes-btn'>
                                    <button className='notes-save-btn' onClick={() => notesClose(true)}>Notes</button>
                                </div> */}
                            </Col>
                        </Col>
                    </>
                    : null
            }
            {/* {
                showConnect ?
                    showNotes ?
                        <>
                            <div className='call-notes'>
                                <h4 className='pt-3'>Notes</h4>
                                <textarea name="" id="" cols="50" rows="6" value={notes} onChange={(e) => { setNotes(e.target.value) }}></textarea>
                                <div className='d-flex justify-content-end gap-2 mb-3'>
                                    <button
                                        className='notes-save-btn'
                                        onClick={() => handleUpdateNotes()}
                                    >
                                        save
                                    </button>
                                    <button
                                        className='notes-save-btn bg-grey'
                                        onClick={() => notesClose(false)}
                                    >
                                        cancel
                                    </button>
                                </div>
                            </div>
                        </>
                        : null

                    : null
            } */}
        </>
    )
}

export default CallConnect;