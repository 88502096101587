import { Button, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import useInterval from 'use-interval';
import Swal from 'sweetalert2';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GetAstrologerStatus, GetAstrologerProfile, UpdateCallActive, UpdateChatActive, successMessage, errorMessage } from '../../../../Redux/Slice'

import NextOnline from './NextOnline';
import ToggleSwitch from './ToggleSwitch';


const StatusCard = () => {
    const dispatch = useDispatch();
    let token = localStorage.getItem("astrologer-auth-token");

    const { AstrologerStatus, AstrologerProfile, success, errors } = useSelector((state) => state.Astromind)

    const [isCallToggled, setIsCallToggled] = useState();
    const [isChatToggled, setIsChatToggled] = useState();
    const [showSelecttime, setShowSelecttime] = useState(false);
    const [isCallOnlinetime, setIsCallOnlinetime] = useState(false);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        dispatch(GetAstrologerStatus({ token }));
    }, []);
    useInterval(() => {
        dispatch(GetAstrologerStatus({ token }));
    }, 10000);

    useEffect(() => {
        setIsCallToggled(AstrologerStatus?.data?.data?.astrologer?.call_active_status);
        setIsChatToggled(AstrologerStatus?.data?.data?.astrologer?.chat_active_status);
    }, [AstrologerStatus]);



    const handleClose = (value) => {
        setShowSelecttime(false);
    }

    const handleChatToggle = (value) => {
        setIsChatToggled(value);
        let chatStatus = value ? 1 : 0;
        dispatch(UpdateChatActive({ token, chatStatus }));
        setStatus(AstrologerStatus?.data?.data?.astrologer?.chat_active_status);
    };

    const handleCallToggle = (value) => {
        setIsCallToggled(value);
        let callStatus = value ? 1 : 0;
        dispatch(UpdateCallActive({ token, callStatus }));
        setStatus(AstrologerStatus?.data?.data?.astrologer?.call_active_status);
    };

    useEffect(() => {
        if (success?.status === 200) {
            dispatch(GetAstrologerStatus({ token }));
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#097500",
                confirmButtonColor: "#097500",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(successMessage({ success: '' }));
                if (status) {
                    setShowSelecttime(true);
                }
            })
        }
        else if (errors?.status === 400) {
            dispatch(GetAstrologerStatus({ token }));
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));
            })
        }
    }, [success, errors]);

    return (
        <>
            <Col lg={12} sm={12} className='status-card mb-3'>
                <Col lg={12} sm={12} className='d-flex gap-5'>
                    <Col lg={2} sm={2}><p className='title'>Type</p></Col>
                    <Col lg={2} sm={2}><p className='title'>Your Price</p></Col>
                    <Col lg={2} sm={2}><p className='title'>Status</p></Col>
                    <Col lg={4} sm={4}><p className='title'>Next Online Time</p></Col>
                </Col>
                <Col lg={12} sm={12} className='d-flex gap-5'>
                    <Col lg={2} sm={2}><p className='description'>Chat</p></Col>
                    <Col lg={2} sm={2}><p className='description'>₹ {AstrologerProfile?.data?.data?.astrologer?.myrate}</p></Col>
                    <Col lg={2} sm={2} className='d-flex justify-content-center align-items-center'>
                        <ToggleSwitch activeStatus={isChatToggled} onToggle={handleChatToggle} />
                    </Col>
                    <Col lg={4} sm={4} className='d-flex justify-content-center align-items-center'>
                        <Button
                            className={isChatToggled ? "online-switch disabled" : "online-switch active"}
                            onClick={() => { 
                                setShowSelecttime(true) 
                                setIsCallOnlinetime(false)
                            }}
                        >
                            {
                                AstrologerStatus?.data?.data?.astrologer?.chat_next_activetime || AstrologerStatus?.data?.data?.astrologer?.chat_next_activedate ?
                                    AstrologerStatus?.data?.data?.astrologer?.chat_next_activedate + "," + AstrologerStatus?.data?.data?.astrologer?.chat_next_activetime
                                    : "Select Date and Time"
                            }
                        </Button>
                    </Col>
                </Col>

                <Col lg={12} sm={12} className='d-flex gap-5'>
                    <Col lg={2} sm={2}><p className='description'>Call</p></Col>
                    <Col lg={2} sm={2}><p className='description'>₹ {AstrologerProfile?.data?.data?.astrologer?.myrate}</p></Col>
                    <Col lg={2} sm={2} className='d-flex justify-content-center align-items-center'>
                        <ToggleSwitch activeStatus={isCallToggled} onToggle={handleCallToggle} />
                    </Col>
                    <Col lg={4} sm={4} className='d-flex justify-content-center align-items-center'>
                        <Button
                            className={isCallToggled ? "online-switch disabled" : "online-switch active"}
                            onClick={() => {
                                setShowSelecttime(true)
                                setIsCallOnlinetime(true)
                            }}>
                            {
                                AstrologerStatus?.data?.data?.astrologer?.call_next_activetime || AstrologerStatus?.data?.data?.astrologer?.call_next_activedate ?
                                    AstrologerStatus?.data?.data?.astrologer?.call_next_activedate + "," + AstrologerStatus?.data?.data?.astrologer?.call_next_activetime
                                    : "Select Date and Time"
                            }
                        </Button>
                    </Col>
                </Col>
            </Col>
            {
                showSelecttime ?
                    <NextOnline selecttime={showSelecttime} handleClose={handleClose} call={isCallOnlinetime} chat={!isCallOnlinetime} />
                    : null
            }



        </>
    )
}

export default StatusCard;