import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "antd";
import Swal from 'sweetalert2';

import { MdEdit } from "react-icons/md";

import BankDetailEdit from "./BankDetailEdit";
import MainPagetitle from '../../layouts/MainPagetitle';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAstrologerProfile, successMessage, errorMessage } from '../../../Redux/Slice';

const BankDetails = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("astrologer-auth-token");
    const { AstrologerProfile, success, errors } = useSelector((state) => state.Astromind);

    const [viewEdit, setViewEdit] = useState(false);
    const [idproofFile, setIdproofFile] = useState();
    const [passbookFile, setPassbookFile] = useState();

    const cancelEdit = (value) => {
        setViewEdit(value)
    }

    useEffect(() => {
        if (AstrologerProfile?.data?.data?.files.length > 0) {
            AstrologerProfile?.data?.data?.files.map((file) => {
                if (file.filefor === "idproof") {
                    setIdproofFile(file);
                }
                else if (file.filefor === "passbook") {
                    setPassbookFile(file)
                }
            })
        }
        else {
            setIdproofFile(null);
            setPassbookFile(null);
        }

        if (success?.status === 200) {
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#36AA00",
                confirmButtonColor: "#36AA00",
                confirmButtonText: "Okay",
                timer : 5000,
            }).then(() => {
                    dispatch(successMessage(' '));
                    dispatch(GetAstrologerProfile(token));
                    setViewEdit(false);
            })
        }
        else if (errors) {
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer : 5000,
            }).then(() => {
                    dispatch(errorMessage(' '));
            })
        }
    }, [success, errors, dispatch, token, AstrologerProfile]);

    return (
        <>
            <MainPagetitle pageTitle="Bank Details" parentTitle="Home" />
            {viewEdit ?
                <BankDetailEdit canceledit={cancelEdit} idProof={idproofFile} passBook={passbookFile} /> :
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col lg={10}>
                            <form action="" >
                                <div className="details__wrapper register-form mb-5">
                                    <Col lg={12} sm={12} className="p-3">
                                        <div className="d-flex justify-content-end">
                                            <button className="edit-button" onClick={() => setViewEdit(true)}>
                                                Edit
                                                <MdEdit className="icon mx-2" />
                                            </button>
                                        </div>
                                        <div className="mb-4">
                                            <h3>Bank Details</h3>
                                        </div>
                                        <div className="d-flex gap-3 bank-detail">
                                            <div className="form__item mb-4">
                                                <label>Bank account holder name</label>
                                                <input className="px-3" value={AstrologerProfile?.data?.data?.bank?.accountholder_name} disabled />
                                            </div>
                                            <div className="form__item mb-4 ">
                                                <label>Bank name</label>
                                                <input className="px-3" value={AstrologerProfile?.data?.data?.bank?.bank_name} disabled />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-3 bank-detail">
                                            <div className="form__item mb-4">
                                                <label>Bank account number</label>
                                                <input className="px-3" value={AstrologerProfile?.data?.data?.bank?.accountnumber} disabled />
                                            </div>
                                            <div className="form__item mb-4">
                                                <label>Branch Name</label>
                                                <input className="px-3" value={AstrologerProfile?.data?.data?.bank?.branch} disabled />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-3 bank-detail">
                                            <div className="form__item mb-4">
                                                <label>IFSC Code</label>
                                                <input className="px-3" value={AstrologerProfile?.data?.data?.bank?.IFSCcode} disabled />
                                            </div>
                                            <div className="form__item mb-4">
                                                <label>Pan number</label>
                                                <input className="px-3" value={AstrologerProfile?.data?.data?.bank?.pancard} disabled />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-3 bank-file-upload ">
                                            <div className="form__item mb-4">
                                                <label>ID Proof </label>
                                                <div className="d-flex">
                                                    {idproofFile?.file ?
                                                        <Button type={"primary"} href={idproofFile?.file}>
                                                            Download
                                                        </Button>
                                                        : <p>file not found</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form__item  mb-4">
                                                <label>Bank passbook</label>
                                                <div className="d-flex">
                                                    {passbookFile?.file ?
                                                        <Button type={"primary"} href={passbookFile?.file}>
                                                            Download
                                                        </Button>
                                                        : <p>file not found</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
};
export default BankDetails;
