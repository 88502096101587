import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";

import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { UpdateNextOnline } from '../../../../Redux/Slice'


const NextOnline = ({ selecttime, handleClose, call, chat }) => {
    const dispatch = useDispatch()
    let token = localStorage.getItem("astrologer-auth-token");

    const { AstrologerProfile, success, errors } = useSelector((state) => state.Astromind)
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("");


    const handleExit = () => {
        handleClose(false);
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const givenDate = date;
        const selectedDate = new Date(time);
        
        return givenDate.getDate() === currentDate.getDate() &&
            givenDate.getMonth() === currentDate.getMonth() &&
            givenDate.getFullYear() === currentDate.getFullYear() ?
            currentDate.getTime() < selectedDate.getTime()
            : true;
    };

    const handleSelect = (e) => {
        e.preventDefault();
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const modifyDate = date.toLocaleDateString('en-IN', options);

        // let hours = time.slice(0, 2);
        // let minute = time.slice(3, 6);
        // let AmOrPm = hours >= 12 ? 'pm' : 'am';
        // hours = (hours % 12) || 12;

        let Online_time = new Date(time.getTime()).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

        if (call) {
            let onlineTime = {
                call_date: modifyDate,
                call_time: Online_time
            }
            dispatch(UpdateNextOnline({ token, onlineTime }))
        }
        else if (chat) {
            let onlineTime = {
                chat_date: modifyDate,
                chat_time: Online_time
            }
            dispatch(UpdateNextOnline({ token, onlineTime }))
        }
    }
    return (
        <>
            <Modal show={selecttime} onHide={handleExit} centered className='next-online-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Next Online Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col lg={12} className='next-online-select'>
                        <Col lg={5} >
                            <h5 >Select Date </h5>
                            <DatePicker
                                className="px-3"
                                showIcon
                                selected={date}
                                onChange={(value) => { setDate(value) }}
                                monthsShown={1}
                                // showYearDropdown
                                // dropdownMode="select"
                                minDate={new Date()}
                            />
                        </Col>
                        <Col lg={5} >
                            <h5 >Select Time </h5>
                            {/* <input
                                type='time'
                                name='time'
                                id='time'
                                value={time}
                                onChange={(e) => { setTime(e.target.value) }}
                            /> */}
                            <DatePicker
                                placeholderText="Click to select a time"
                                selected={time}
                                onChange={(value) => { setTime(value) }}
                                filterTime={filterPassedTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={30}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                        </Col>
                    </Col>

                    <div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleExit}>
                        Close
                    </Button>
                    <Button variant="warning" class="btn btn-warning" onClick={handleSelect}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default NextOnline;