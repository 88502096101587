import { SVGICON } from "../../constant/theme";

import DashboardIcon from '../../../images/svg/dashboard-icon.svg';
import OrderHistoryIcon from '../../../images/svg/Order-history-icon.svg';
import BankIcon from '../../../images/svg/bank-icon.svg';
import WalletIcon from '../../../images/svg/wallet-icon.svg';
import WaitinglistIcon from '../../../images/svg/waiting-list-icon.svg';
import RatingsIcon from '../../../images/svg/ratings-icon.svg';
import FollowerIcon from '../../../images/svg/follower-icon.svg';
import OfferIcon from '../../../images/svg/offer-icon.svg';
import NotificationIcon from '../../../images/svg/notification-icon.svg'

export const MenuList = [
    //Content
    // {
    //     title: 'YOUR COMPANY',
    //     classsChange: 'menu-title'
    // },
    //Dashboard
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle: DashboardIcon,
        to: '/dashboard/',
        // content: [
            
        //     {
        //         title: 'Dashboard Light',
        //         to: '/dashboard',					
        //     },
        //     {
        //         title: 'Dashboard Dark',
        //         to: '/dashboard-dark',
                
        //     },            
        // ],
    },
    {   
        title:'Work Desk',
        iconStyle: WaitinglistIcon,
        to: '/work-desk/',
    },
    {   
        title:'Order History',
        iconStyle: OrderHistoryIcon,
         content: [
            
            // {
            //     title: 'Chat History',
            //     to: '/call-logs',					
            // },
            {
                title: 'Call Logs',
                to: '/call-logs/',
                
            },
            {
                title: 'Chat Logs',
                to: '/chat-logs/',
                
            },            
        ],
    },
    {   
        title:'Bank Details',
        iconStyle: BankIcon,
        to: '/bank-details/',
    },
    {   
        title:'Earnings',
        iconStyle: WalletIcon,
        to: '/earnings/',
    },
    {   
        title:'Followers',
        iconStyle: FollowerIcon,
        to: '/followers/',
    },
    {   
        title:'Ratings & Reviews',
        iconStyle: RatingsIcon,
        to: '/ratings-reviews/',
    },
    {   
        title:'Offers',
        iconStyle: OfferIcon,
        to: '/offers/',
    },
    {   
        title:'Notifications',
        iconStyle: NotificationIcon,
        to: '/notifications/',
    },
    
]