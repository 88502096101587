import Modal from 'react-bootstrap/Modal';

import BirthChart from './BirthChart';

function ChatChart({ show, closeChart, waitingList }) {

    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={closeChart}
                className='chat-chart-modal'
            >
                <Modal.Header closeButton>
                   <h4>Birth Chart</h4>
                </Modal.Header>
                <Modal.Body>
                    <BirthChart list={waitingList} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChatChart;