import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios';

const initialState = {
    Loading: '',
    success: '',
    errors: '',
    IpAddress: null,
    OtpShow: false,
    Login: false,
    AstrologerProfile: '',
    AstrologerStatus: null,
    WaitingList: null,
    CallProcess: null,
    CallStatus: null,
    CallNotes: null,
    InprogressChatStatus: null,
    ChatExtendedStatus: null,
    OrderHistory: null,
    WalletAmount: null,
    EarningsHistory: null,
    Followers: null,
    ChatHistory: null,
    UserReviews: null,
    Offers: null,
    Tickets: null,
    TicketMessages: null,
    Notifications: null,
};

export const GetIPAddress = createAsyncThunk(
    'page/IPAddress',
    async (_, { rejectWithValue }) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            }
            const response = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_API_IPINFO}`, { headers: headers })
            if (response?.status == 200 || response.status == 201) {
                const { data } = response
                return data;
            }
        } catch (error) {
            return rejectWithValue('');
        }
    }
)

export const CheckLoginStatus = createAsyncThunk(
    'page/CheckLoginStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/check-session`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const SendOtp = createAsyncThunk(
    'page/sendOtp',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/astrologer/send-otp`, val);
            if (response.status === 200 || response.status === 201) {
                const { data } = response;
                return data;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const ValitateOtp = createAsyncThunk(
    'page/otpValidate',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/otp-validate`, val);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const checkAutoLogin = createAsyncThunk(
    'page/checkAutoLogin',
    async (val, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem('astrologer-auth-token');
            if (!token) {
                return val('/login');;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const LogOut = createAsyncThunk(
    'page/LogOut',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/logout`,
                { headers: headers });
            const token = localStorage.removeItem('astrologer-auth-token');
            if (!token) {
                window.location.reload(false);
                return val?.navigate('/login');
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologerProfile = createAsyncThunk(
    'page/AstrologerProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-profile`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteAstrologerProfile = createAsyncThunk(
    'page/DeleteAstrologerProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/delete-account`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const ReactiveAstrologerProfile = createAsyncThunk(
    'page/ReactiveAstrologerProfile',
    async (val, { rejectWithValue }) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/remove-delete-request/${val?.id}`);
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAstrologerStatus = createAsyncThunk(
    'page/GetAstrologerStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-online-status`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateCallActive = createAsyncThunk(
    'page/UpdateCallActive',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/call-chat-live-status?call=${val.callStatus}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const UpdateChatActive = createAsyncThunk(
    'page/UpdateChatActive',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/call-chat-live-status?chat=${val.chatStatus}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const UpdateAstrologerProfile = createAsyncThunk(
    'page/UpdateAstrologerProfile',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/edit-profile`, val.updateData,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateAstrologerFile = createAsyncThunk(
    'page/UpdateAstrologerFile',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            if (val?.ExistFile === null) {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/upload-file`, val.formData,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/update-file/${val?.fileId}`, val.formData,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteAstrologerProfileImg = createAsyncThunk(
    'page/DeleteUserProfileImg',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'multipart/form-data',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/delete-file/${val?.fileId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateBankDetails = createAsyncThunk(
    'page/UpdateBankDetails',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/edit-bankdetails`, val.BankDetailData,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateNextOnline = createAsyncThunk(
    'page/UpdateNextOnline',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/call-or-chat-nextactivetime`, val.onlineTime,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetWaitingList = createAsyncThunk(
    'page/GetWaitingList',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-waitinglist`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const AcceptOrRejectCall = createAsyncThunk(
    'page/AcceptOrRejectCall',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.type == "call") {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/astrologer/accept-or-reject-call?status=${val?.status}&waitingId=${val?.waitingId}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == "chat") {
                let currentTime = new Date().toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/message/accept-or-reject-chat?status=${val?.status}&waitingId=${val?.waitingId}&startTime=${currentTime}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetCallStatus = createAsyncThunk(
    'page/GetCallStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-call-status/${val?.orderId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetInprogressChatStatus = createAsyncThunk(
    'page/GetInprogressChatStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/get-chat-status-on-chatting/${val?.orderId}?isAstrologerIn=1`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetChatSession = createAsyncThunk(
    'page/GetChatSession',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-session-messages/${val?.messageSessionId}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetChatExtended = createAsyncThunk(
    'page/GetChatExtended',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/chat-is-extended/${val?.messageSessionId}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const UpdateChatEnd = createAsyncThunk(
    'page/UpdateChatEnd',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/extend-or-endchat/${val?.chatUpdate?.orderId}?isExtended=${val?.chatUpdate?.isExtended}&extendedDuration=${val?.chatUpdate?.extendedDuration}&isendedbyuser=${val?.chatUpdate?.isendedbyuser}&isendedbyastrologer=${val?.chatUpdate?.isendedbyastrologer}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetNotes = createAsyncThunk(
    'page/GetNotes',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-notes?userId=${val?.userId}&subuserId=${val?.subuserId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateNotes = createAsyncThunk(
    'page/UpdateNotes',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/take-notes/`, val?.callNotes,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetMyWallet = createAsyncThunk(
    'page/GetMyWallet',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-mywallet`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetMyEarnings = createAsyncThunk(
    'page/GetMyEarnings',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-myearnings/${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetOrderHistory = createAsyncThunk(
    'page/GetOrderHistory',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-orderhistroy/${val?.page}?pagelimit=${val?.pageLimit}&type=${val?.type}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetFollowers = createAsyncThunk(
    'page/GetFollowers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-followers/${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetReviews = createAsyncThunk(
    'page/GetReviews',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-my-reviews/${val?.page}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetOffers = createAsyncThunk(
    'page/GetOffers',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-my-offers`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const ActiveOrDeactiveOffer = createAsyncThunk(
    'page/ActiveOrDeactiveOffer',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/activate-offer/${val?.offerId}?status=${val?.status}`, { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

// New Support Chat
export const CreateTicket = createAsyncThunk(
    'page/CreateTicket',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/create-support-ticket`, val?.data,
                { headers: headers }
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);

export const GetAllTickets = createAsyncThunk(
    'page/GetAllTickets',
    async (val, { rejectWithValue }) => {
        const headers = {
            authorization: val.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-my-tickets`,
                { headers: headers }
            );
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response);
        }
    },
);


export const GetTicketMessages = createAsyncThunk(
    'page/GetTicketMessages',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/message/get-support-messages/${val?.ticketId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllNotifications = createAsyncThunk(
    'page/GetAllNotifications',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/astrologer/get-notifications`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateNotificationSeenStatus = createAsyncThunk(
    'page/UpdateNotificationSeenStatus',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/set-seen-notification/${val?.id}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }

        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

const ReduxSlice = createSlice({
    name: 'astromind',
    initialState: initialState,
    reducers: {
        errorMessage: (state, action) => {
            state.errors = action.payload.errors;
        },
        successMessage: (state, action) => {
            state.success = action.payload.success;
        },
        loadingStatus: (state, action) => {
            state.Loading = action.payload.loadingStatus;
        },
        loginStatus: (state, action) => {
            state.Login = action.payload.LoginStatus;
            state.OtpShow = action.payload.LoginStatus;
        },
        CallStatusMessage: (state, action) => {
            state.CallProcess = action.payload.CallStatus;
            state.CallStatus = action.payload.CallStatus;
        },
    },
    extraReducers: (builder) => {
        builder

            // Get Ip Address
            .addCase(GetIPAddress.pending, (state) => {
                state.IpAddress = ''
                state.Loading = true
            })
            .addCase(GetIPAddress.fulfilled, (state, action) => {
                state.Loading = false
                state.IpAddress = action.payload
            })
            .addCase(GetIPAddress.rejected, (state, action) => {
                state.Loading = false
                state.IpAddress = ''
            })

            //CheckLoginStatus
            .addCase(CheckLoginStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CheckLoginStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CheckLoginStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Send Otp
            .addCase(SendOtp.pending, (state) => {
                state.Loading = true;
            })
            .addCase(SendOtp.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
                state.OtpShow = true;
            })
            .addCase(SendOtp.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Valitate Otp
            .addCase(ValitateOtp.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ValitateOtp.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.UserProfile = action.payload;
                state.OtpShow = false;
            })
            .addCase(ValitateOtp.rejected, (state, action) => {
                state.errors = action.payload;
                state.UserProfile = {};
                state.Loading = false;
            })

            //Get Astrologer Profile
            .addCase(GetAstrologerProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologerProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologerProfile = action.payload;
            })
            .addCase(GetAstrologerProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologerProfile = {};
            })

            //Update Astrologer Profile
            .addCase(UpdateAstrologerProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateAstrologerProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateAstrologerProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Update Astrologer Profile Image
            .addCase(UpdateAstrologerFile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateAstrologerFile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateAstrologerFile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Delete Astrologer Profile
            .addCase(DeleteAstrologerProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteAstrologerProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteAstrologerProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Reactive Astrologer Profile
            .addCase(ReactiveAstrologerProfile.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ReactiveAstrologerProfile.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(ReactiveAstrologerProfile.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Delete Astrologer Profile Image
            .addCase(DeleteAstrologerProfileImg.pending, (state) => {
                state.Loading = true;
            })
            .addCase(DeleteAstrologerProfileImg.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(DeleteAstrologerProfileImg.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Updated Astrologers Bank Details
            .addCase(UpdateBankDetails.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateBankDetails.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateBankDetails.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Chat Or Call Active Status
            .addCase(GetAstrologerStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAstrologerStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.AstrologerStatus = action.payload;
            })
            .addCase(GetAstrologerStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.AstrologerStatus = '';
            })

            //Update Chat Active Status
            .addCase(UpdateChatActive.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateChatActive.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateChatActive.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Update Call Active Status
            .addCase(UpdateCallActive.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateCallActive.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateCallActive.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Update Next Online Time
            .addCase(UpdateNextOnline.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateNextOnline.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateNextOnline.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //Get Waiting Users List
            .addCase(GetWaitingList.pending, (state) => {
                state.Loading = false;
            })
            .addCase(GetWaitingList.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.WaitingList = action.payload;
            })
            .addCase(GetWaitingList.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.WaitingList = '';
            })

            //Accept or Reject the Users Call
            .addCase(AcceptOrRejectCall.pending, (state) => {
                state.Loading = true;
            })
            .addCase(AcceptOrRejectCall.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.CallProcess = action.payload;
            })
            .addCase(AcceptOrRejectCall.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.CallProcess = null;
            })

            //Get Call Status
            .addCase(GetCallStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetCallStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.CallStatus = action.payload;
            })
            .addCase(GetCallStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.CallProcess = null;
            })

            //Get Inprogress Chat Status 
            .addCase(GetInprogressChatStatus.pending, (state) => {
                state.Loading = false;
            })
            .addCase(GetInprogressChatStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.InprogressChatStatus = action.payload;
            })
            .addCase(GetInprogressChatStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.InprogressChatStatus = null;
            })

            //chat history
            .addCase(GetChatSession.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetChatSession.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ChatHistory = action.payload;
            })
            .addCase(GetChatSession.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ChatHistory = null;
            })

            //Is Chat Extended
            .addCase(GetChatExtended.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetChatExtended.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.ChatExtendedStatus = action.payload;
            })
            .addCase(GetChatExtended.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.ChatExtendedStatus = null;
            })

            //Chat End
            .addCase(UpdateChatEnd.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateChatEnd.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateChatEnd.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = ' ';
            })

            //Get Call Notes for User
            .addCase(GetNotes.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetNotes.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.CallNotes = action.payload;
            })
            .addCase(GetNotes.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.CallNotes = null;
            })

            //Update Call Notes
            .addCase(UpdateNotes.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateNotes.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateNotes.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
            })

            //Get My Wallet Amount
            .addCase(GetMyWallet.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetMyWallet.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.WalletAmount = action.payload;
            })
            .addCase(GetMyWallet.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.WalletAmount = null;
            })

            //Get My Earnings History
            .addCase(GetMyEarnings.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetMyEarnings.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.EarningsHistory = action.payload;
            })
            .addCase(GetMyEarnings.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.EarningsHistory = '';
            })

            //Get My Earnings History
            .addCase(GetOrderHistory.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetOrderHistory.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.OrderHistory = action.payload;
            })
            .addCase(GetOrderHistory.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.OrderHistory = '';
            })

            //Get My followers
            .addCase(GetFollowers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetFollowers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Followers = action.payload;
            })
            .addCase(GetFollowers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Followers = null;
            })

            //Get Users Reviews
            .addCase(GetReviews.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetReviews.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.UserReviews = action.payload;
            })
            .addCase(GetReviews.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.UserReviews = null;
            })

            //Get Offers
            .addCase(GetOffers.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetOffers.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Offers = action.payload;
            })
            .addCase(GetOffers.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Offers = null;
            })

            //Active Or Deactive Offer
            .addCase(ActiveOrDeactiveOffer.pending, (state) => {
                state.Loading = true;
            })
            .addCase(ActiveOrDeactiveOffer.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(ActiveOrDeactiveOffer.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = null;
            })

            //Create a Support Ticket
            .addCase(CreateTicket.pending, (state) => {
                state.Loading = true;
            })
            .addCase(CreateTicket.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(CreateTicket.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })

            //get Support Tickets
            .addCase(GetAllTickets.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllTickets.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Tickets = action.payload;
            })
            .addCase(GetAllTickets.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Tickets = null;
            })

            //get tickets messages
            .addCase(GetTicketMessages.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetTicketMessages.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.TicketMessages = action.payload;
            })
            .addCase(GetTicketMessages.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.TicketMessages = null;
            })

            //Get All Notifications
            .addCase(GetAllNotifications.pending, (state) => {
                state.Loading = true;
            })
            .addCase(GetAllNotifications.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.Notifications = action.payload;
            })
            .addCase(GetAllNotifications.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.Notifications = null;
            })

            //Update Notification Seen Status 
            .addCase(UpdateNotificationSeenStatus.pending, (state) => {
                state.Loading = true;
            })
            .addCase(UpdateNotificationSeenStatus.fulfilled, (state, action) => {
                state.Loading = false;
                state.errors = '';
                state.success = action.payload;
            })
            .addCase(UpdateNotificationSeenStatus.rejected, (state, action) => {
                state.errors = action.payload;
                state.Loading = false;
                state.success = '';
            })
    },

})


export const { errorMessage,
    successMessage,
    loadingStatus, loginStatus, CallStatusMessage } = ReduxSlice.actions;
export default ReduxSlice.reducer;