import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

import MainPagetitle from '../../layouts/MainPagetitle';
import FollowersDetail from "./FollowersDetail";

import EmptyProfile from '../../../images/dashboard/empty-profile.png'
import { FaChevronRight } from "react-icons/fa";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetFollowers } from '../../../Redux/Slice';

function FollowersList() {
    const dispatch = useDispatch();
    const token = localStorage.getItem("astrologer-auth-token");

    const { Followers, errors } = useSelector((state) => state.Astromind);

    const [show, setShow] = useState(false);
    const [followerDetail, setFollowerDetail] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const ShowClose = () => {
        setShow(false);
    }
    useEffect(() => {
        dispatch(GetFollowers({ token: token, page: currentPage }));
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(Followers?.data?.pagination?.currentpage);
    }, [Followers]);

    const handleFollowDetail = (follower) => {
        setShow(true);
        setFollowerDetail(follower);
    }

    const changePage = (value) => {
        let page = value;
        dispatch(GetFollowers({ token, page }));
    }

    const nextPage = () => {
        const page = Number(currentPage) + 1;
        if (page <= Followers?.data?.pagination?.totalPage) {
            dispatch(GetFollowers({ token, page }));
        }
    }

    const previousPage = () => {
        const page = Number(currentPage) - 1;
        if (page >= 1) {
            dispatch(GetFollowers({ token, page }));
        }
    }


    return (
        <>
            <MainPagetitle pageTitle="Followers" parentTitle="Home" />
            {
                show ?
                    <FollowersDetail profileShow={show} profileClose={ShowClose} follower={followerDetail} />
                    : null
            }
            <Row className="margin-3">
                <Col lg={12}>
                    <h2>Followers</h2>
                </Col>
                {
                    Followers?.data?.data?.followers?.length > 0 ?
                        <>
                            <Col lg={12} className='followers-list'>
                                {Followers?.data?.data?.followers?.map((follower) => (
                                    <Col lg={4} className='follower-card'>
                                        <Col lg={3} className='follower-img'>
                                            <img src={follower?.files[0]?.file ? follower?.files[0]?.file : EmptyProfile} alt="" className='img-fluid' />
                                        </Col>
                                        <Col lg={6} className='d-flex flex-column justify-content-center'>
                                            <h5>{follower.name}</h5>
                                            <p>{follower?.date ? follower?.date : "24 Apirl 2024 , 11:30am"}</p>
                                        </Col>
                                        <Col lg={2}>
                                            <div className='new-user'>
                                                <p>{follower.status ? follower.status : "New"}</p>
                                            </div>
                                        </Col>
                                        <Col lg={1} className='more-icon' >
                                            <FaChevronRight className='icon' onClick={() => { handleFollowDetail(follower) }} />
                                        </Col>
                                    </Col>
                                ))}
                            </Col>
                        </>
                        :
                        <div className='no-data'>
                            <p>No sufficient data available!</p>
                        </div>
                }
                {
                    Followers?.data?.pagination?.totalPage?.length > 0 ?
                        <>
                            <Col lg={12} className='d-flex justify-content-center mt-4 pagination'>
                                <Pagination className="px-4">
                                    <Pagination.Prev onClick={() => previousPage()} />
                                    {[...new Array(Followers?.data?.pagination?.totalPage)].map((arr, index) => {
                                        return (
                                            <Pagination.Item
                                                onClick={() => changePage(index + 1)}
                                                key={index + 1}
                                                active={index + 1 === Number(currentPage)}
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        )
                                    })}
                                    <Pagination.Next onClick={() => nextPage()} />
                                </Pagination>
                            </Col>
                        </>
                        :
                        null
                }

            </Row>
        </>
    )
}

export default FollowersList;