import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import useInterval from 'use-interval';

import { PiPhoneCallFill } from "react-icons/pi";
import { BsChatDotsFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetWaitingList } from '../../../Redux/Slice';

function OrderAlert() {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { WaitingList } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("astrologer-auth-token");

    const [showAlert, setShowAlert] = useState(false);
    // const [count, setCount] = useState(0);
    const isShow = localStorage.getItem("isShow");
    const orderId = localStorage.getItem("orderId");
    const chatOrderId = localStorage.getItem("chatOrderId");

    useInterval(() => {
        dispatch(GetWaitingList(token));
    }, 10000);

    useEffect(() => {
        if (WaitingList?.data?.data && !(isShow)) {
            localStorage.setItem("isShow", true);
            setShowAlert(true);
        }
        else if (WaitingList?.data?.message == "No Waiting Data") {
            localStorage.removeItem("isShow");
            setShowAlert(false);
        }
    }, [WaitingList]);

    const handleView = () => {
        setShowAlert(false);
        Navigate('/work-desk/')
    }
    return (
        <>{
            showAlert && WaitingList?.data?.data  ?
                <div className="alert-card">
                    <div className="alert-icon-circle">
                        {
                            WaitingList?.data?.data?.waiting?.type == "call" ?
                                <PiPhoneCallFill className="icon" />
                                : <BsChatDotsFill className="icon" />
                        }
                    </div>
                    <div className="alert-message">
                        <div className='d-flex justify-content-end'>
                            <IoClose className="icon" onClick={() => { setShowAlert(false) }} />
                        </div>
                        <p>{WaitingList?.data?.data?.waiting?.type} from {WaitingList?.data?.data?.waiting?.name}</p>
                        <button onClick={() => { handleView() }}>view</button>
                    </div>
                </div>
                : null
        }

        </>
    )
}

export default OrderAlert;