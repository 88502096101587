import {  Col } from 'react-bootstrap';
import ValentineOffer from '../../../../images/dashboard/valentine-offer.png'
import UpcomingOffers from '../../../../images/dashboard/upcoming-offers.png'
import TodayAstrology from '../../../../images/dashboard/today-astrology.png'

const OfferCard = () => {
    return (
        <>
            <Col lg={12}  className='d-flex flex-wrap gap-3'>
                <Col lg={4} className='offer-card'>
                    <img src={ValentineOffer} alt="" />
                    <h5>Valentine's Day Offer</h5>
                </Col>
                <Col lg={4} className='offer-card'>
                    <img src={UpcomingOffers} alt="" />
                    <h5>Upcoming  Offers and Events</h5>
                </Col>
                <Col lg={4} className='offer-card'>
                    <img src={TodayAstrology} alt="" />
                    <h5>Today astrology</h5>
                </Col>
            </Col>
        </>
    )
}

export default OfferCard;