import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Container, Row, Col } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

import { IoArrowBackOutline } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa";

import MainPagetitle from '../../layouts/MainPagetitle';

import ChatPage from './ChatPage';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { CreateTicket, GetAllTickets, successMessage, errorMessage } from '../../../Redux/Slice';

import { v4 as uuidv4 } from 'uuid';

function SupportChat({ socket }) {
    const dispatch = useDispatch();
    const { success, errors, Loading, Tickets } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('astrologer-auth-token');

    const [ticket, setTicket] = useState({});
    const [showCreate, setShowCreate] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [key, setKey] = useState('faqs');

    const isLaptop = window.innerWidth > 950 ? true : false;

    const handleClose = () => setShowCreate(false);

    const createSupportTicket = (ticketIssue) => {
        const newUuid = uuidv4();
        const data = {
            issue: ticketIssue?.issue,
            issuecategory: ticketIssue?.issuecategory,
            issuedescription: ticketIssue?.issueDescription,
            room: newUuid
        }
        dispatch(CreateTicket({ token, data }));
        joinRoom(newUuid);
    }

    const formik = useFormik({
        initialValues: {
            issue: '',
            issuecategory: '',
            issueDescription: '',
        },
        onSubmit: async (values, { resetForm }) => {
            let ticketIssue = {
                issue: values?.issue,
                issuecategory: values?.issuecategory,
                issueDescription: values?.issueDescription
            }
            createSupportTicket(ticketIssue);
        },
        validate: (values) => {
            const errors = {};

            if (!values.issue) {
                errors.issue = "Required*";
            }
            if (!values.issuecategory) {
                errors.issuecategory = "Required*";
            }
            if (!values.issueDescription) {
                errors.issueDescription = "Required*";
            }

            return errors;
        }
    })

    const joinRoom = (uuid) => {
        console.log("joining room ", uuid);
        socket.emit("joinsupport", { room: uuid, role: 'astrologer' });
    }

    useEffect(() => {
        dispatch(GetAllTickets({ token }));
    }, [dispatch, token])

    const openSupportChat = (ticket) => {
        setTicket(ticket);
        if (!ticket?.isClosed) {
            socket.emit("joinsupport", { room: ticket?.room, role: 'astrologer' });
        }
        setIsChatOpen(true);
    }

    useEffect(() => {
        if (success) {
            if (success?.data?.data?.isCreated) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then(() => {
                    localStorage.setItem("ticketId", success?.data?.data?.ticketId);
                    dispatch(GetAllTickets({ token }));
                    setKey('tickets');
                    handleClose();
                    dispatch(successMessage(''));
                })
            }
        }
        else if (errors) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then(() => {
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, success, errors]);

    return (
        <>
            <MainPagetitle pageTitle="Customer Support" parentTitle="Home" />

            <Modal
                show={showCreate}
                onHide={handleClose}
                className='issue-modal'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Enter Your Issue</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col lg={12} className='create-issue'>
                        <Col lg={12} className='mb-3'>
                            <h6 htmlFor="">Issue<span className='text-darkOrange px-1'>*</span></h6>
                            <input
                                type="text"
                                id='issue'
                                name='issue'
                                placeholder='Enter Your Issue'
                                value={formik.values.issue}
                                onChange={formik.handleChange}
                            />
                            <p className='error'>{formik.errors.issue}</p>
                        </Col>
                        <Col lg={12} className='mb-3'>
                            <h6 htmlFor="">Issue Category<span className='text-darkOrange px-1'>*</span></h6>
                            <select
                                id='issuecategory'
                                name='issuecategory'
                                value={formik.values.issuecategory}
                                onChange={formik.handleChange}
                            >
                                <option value="">Choose</option>
                                <option value="Payment">Payment</option>
                            </select>
                            <p className='error'>{formik.errors.issuecategory}</p>
                        </Col>
                        <Col lg={12} className='mt-4'>
                            <textarea
                                id='issueDescription'
                                name='issueDescription'
                                placeholder='Descripe about your issue'
                                value={formik.values.issueDescription}
                                onChange={formik.handleChange}
                            />
                            <p className='error'>{formik.errors.issueDescription}</p>
                        </Col>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={formik.handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Container fluid className=''>
                <Row className='support-chat'>
                    {
                        !isChatOpen || isLaptop ?
                            <Col lg={5} className='helf'>
                                <div className='title'>
                                    <h5 className='text-white'>Help</h5>
                                </div>
                                <div className='helf-inside'>
                                    <Tabs
                                        id="controlled-tab-example"
                                        className="mb-3"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                    >
                                        <Tab eventKey="faqs" title="FAQ">
                                            <Accordion >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header >
                                                        Genernal FAQs
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header >
                                                        Genernal FAQs
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                        aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                        culpa qui officia deserunt mollit anim id est laborum.
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Tab>
                                        <Tab eventKey="tickets" title="Tickets">
                                            {
                                                Tickets?.data?.data?.tickets?.map((ticket, index) => {
                                                    let indianTime = new Date(ticket?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                                    return (
                                                        <>
                                                            <div className='ticket' onClick={() => openSupportChat(ticket)}>
                                                                <div>
                                                                    <p className='fw-bold'>{ticket?.issuecategory}</p>
                                                                    <p className='date' >{indianTime}</p>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        className={`status ${ticket?.status === 'pending' ? 'pending' : ticket?.status === 'open' ? 'open' : ticket?.status === 'close' ? 'close ' : null} `}>
                                                                        {ticket?.status === 'pending' ? 'Pending' : ticket?.status === 'open' ? 'Opened' : ticket?.status === 'close' ? 'Resolved ' : null}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Tab>
                                    </Tabs>
                                    <button
                                        className='create-ticket'
                                        onClick={() => setShowCreate(true)}
                                    >
                                        Create New Ticket
                                    </button>
                                </div>
                            </Col>
                            : null
                    }
                    {
                        isChatOpen || isLaptop ?
                            <Col lg={7} className='chat-screen'>
                                <div className='title'>
                                    {
                                        !isLaptop ?
                                            <IoArrowBackOutline
                                                className='icon'
                                                onClick={() => setIsChatOpen(!isChatOpen)}
                                            />
                                            : null
                                    }
                                    <h5 className='text-white'>Support Chat</h5>
                                </div>
                                <div className='chat'>
                                    <ChatPage socket={socket} ticket={ticket} />
                                </div>
                            </Col>
                            : null
                    }
                </Row>
            </Container>
        </>
    )
}

export default SupportChat;