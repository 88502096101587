import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetAstrologerProfile, DeleteAstrologerProfile, LogOut, DeleteAstrologerProfileImg, successMessage, errorMessage } from '../../../../Redux/Slice';

import MainPagetitle from '../../../layouts/MainPagetitle';

import EditProfile from "./EditProfile";
import ImageUpdate from "./ImageUpdate";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import EmptyProfile from '../../../../images/dashboard/empty-profile.png';

const AppProfile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { AstrologerProfile, success, errors } = useSelector((state) => state.Astromind);
	const token = localStorage.getItem("astrologer-auth-token");
	const description = AstrologerProfile?.data?.data?.astrologer?.description ? AstrologerProfile?.data?.data?.astrologer?.description : null;
	const language = AstrologerProfile?.data?.data?.astrologer?.language ? AstrologerProfile?.data?.data?.astrologer?.language : null;

	const [editShow, setEditShow] = useState(false);
	const [changeProfileShow, setChangeProfileShow] = useState(false);
	const [fileData, setFileData] = useState(null);
	const [profileImage, setProfileImage] = useState(null);

	const editClose = (value) => {
		setEditShow(value);
	}
	const ChangeProfileClose = (value) => {
		setChangeProfileShow(value);
	}

	const deleteAccount = () => {
		Swal.fire({
			title: "Are you sure want to Delete your account?",
			text: "After 14 days, the Account can be Deleted Permanently.",
			icon: "warning",
			iconColor: "#CA0505",
			showCancelButton: true,
			cancelButtonColor: "#025BFD",
			confirmButtonColor: "#CA0505",
			confirmButtonText: "Delete Account"
		}).then((result) => {
			if (result?.isConfirmed) {
				dispatch(DeleteAstrologerProfile({ token }));
			}
		})
	}

	const updateProfile = () => {
		Swal.fire({
			title: "Are you sure want to Update your profile?",
			icon: "warning",
			iconColor: "#CA0505",
			showCancelButton: true,
			cancelButtonColor: "#025BFD",
			confirmButtonColor: "#CA0505",
			confirmButtonText: "Update"
		}).then((result) => {
			if (result?.isConfirmed) {
				setEditShow(true);
			}
		})
	}

	const updateProfileImg = () => {
		setChangeProfileShow(true);
		setFileData({
			fileId: profileImage?.id,
			fileFor: "profile",
			existFile: profileImage
		})
	}

	const deleteProfileImg = () => {
		const fileId = profileImage?.id;

		Swal.fire({
			title: "Are you sure want to Delete your Profile Image?",
			icon: "warning",
			iconColor: "#CA0505",
			showCancelButton: true,
			cancelButtonColor: "#025BFD",
			confirmButtonColor: "#CA0505",
			confirmButtonText: "Delete"
		})
			.then((result) => {
				if (result.isConfirmed) {
					dispatch(DeleteAstrologerProfileImg({ token, fileId }))
				}
			})
	}

	useEffect(() => {
		if (AstrologerProfile?.data?.data?.files.length > 0) {
			AstrologerProfile?.data?.data?.files.map((file) => {
				if (file.filefor === "profile") {
					setProfileImage(file);
				}
			})
		}
		else {
			setProfileImage(null)
		}

		if (success?.status === 200) {
			if (success?.data?.data?.isDeletedRequested) {
				Swal.fire({
					title: success?.data?.message,
					icon: "success",
					iconColor: "#36AA00",
					confirmButtonColor: "#36AA00",
					confirmButtonText: "Okay",
					timer: 5000,
				}).then(() => {
					dispatch(successMessage(''));
					dispatch(LogOut({ token, navigate }));
				})
			}
			else {

				Swal.fire({
					title: success?.data?.message,
					icon: "success",
					iconColor: "#36AA00",
					confirmButtonColor: "#36AA00",
					confirmButtonText: "Okay",
					timer: 5000,
				}).then(() => {
					dispatch(GetAstrologerProfile(token));
					setChangeProfileShow(false);
					setEditShow(false);
					dispatch(successMessage(''));
				})
			}

		}

		else if (errors) {
			dispatch(GetAstrologerProfile(token));
			Swal.fire({
				title: errors?.message,
				icon: "error",
				iconColor: "#CA0505",
				confirmButtonColor: "#CA0505",
				confirmButtonText: "Okay",
				timer: 5000,
			}).then((rsult) => {
					dispatch(errorMessage({ errors: '' }));
			})
		}
	}, [success, errors, dispatch, token, AstrologerProfile, profileImage]);

	return (
		<Fragment>
			<MainPagetitle pageTitle="Profile" parentTitle="Home" />
			{
				editShow ?
					<EditProfile
						editShow={editShow}
						editClose={editClose}
						profile={AstrologerProfile}
					/>
					: null
			}
			{
				changeProfileShow ?
					<ImageUpdate
						profileShow={changeProfileShow}
						fileData={fileData}
						profileClose={ChangeProfileClose}
					/>
					: null
			}

			<Container fluid >
				<Row >
					<Col lg={12} >
						<div className="profile card card-body px-3 pt-3 pb-0">
							<div className="profile-head">
								<div className="photo-content ">
									<div className="cover-photo rounded">
										{/* <div className="profile-update d-flex gap-4 justify-content-end mx-3 pt-2">
											<div className="profile-icon">
												<MdEdit className="icon" />
											</div>
											<div className="profile-icon">
												<RiDeleteBin6Fill className="icon" />
											</div>
										</div> */}
									</div>
								</div>
								<div className="profile-info">
									<div className="profile-photo">
										<img
											src={profileImage ? profileImage?.file : EmptyProfile} className="img-fluid rounded-circle" alt="profile" />
										<div className="profile-photo-update" >
											<div className="profile-icon" onClick={updateProfileImg}>
												<MdEdit className="icon" />
											</div>
											{/* <div className="profile-icon" onClick={deleteProfileImg}>
												<RiDeleteBin6Fill className="icon" />
											</div> */}
										</div>
									</div>
									<div className="profile-details">
										<div className="profile-name px-3 pt-2">
											<h4 className="text-primary mb-0">{AstrologerProfile?.data?.data?.astrologer?.name}</h4>
											<p>Astrologer</p>
										</div>
									</div>
								</div>
								<Col lg={2} className="followers-card" >
									<div className="card">
										<div className="card-body">
											<div className="d-flex text-center">
												<div className="col">
													<h3 className="m-b-0 ">{AstrologerProfile?.data?.data?.followerCount}</h3><span>Follower</span>
												</div>

												<div className="col">
													<h3 className="m-b-0">{AstrologerProfile?.data?.data?.reviewsCount}</h3> <span>Reviews</span>
												</div>
											</div>
										</div>
									</div>
								</Col>
							</div>
						</div>
					</Col>
				</Row>
				<Row >

					<Col xl={12}>
						<div className="card">
							<div className="card-body">
								<div className="profile-update d-flex gap-4 justify-content-end">
									<div onClick={updateProfile} >
										<MdEdit className="icon" />
									</div>
									{/* <div onClick={deleteAccount} >
										<RiDeleteBin6Fill className="icon" />
									</div> */}
								</div>
								<div className="profile-tab">
									<div className="custom-tab-1">
										<div id="about-me" eventKey='About'>
											<div className="profile-about-me">
												<div className="pt-4 border-bottom-1 pb-3">
													<h4 >About</h4>
													<p className="mb-2">
														{AstrologerProfile?.data?.data?.astrologer?.about}
													</p>

												</div>
											</div>
											<div className="d-flex flex-wrap">
												<Col xl={5} sm={6} className="profile-personal-info">
													<h4 className="mb-3">
														Personal Information
													</h4>
													<div className="d-flex mb-2">
														<Col lg={4} >
															<h5 className="f-w-500"> Name
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8} >
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.name ?
																		AstrologerProfile?.data?.data?.astrologer?.name
																		: "none"
																}
															</span>
														</Col>
													</div>

													<div className="d-flex mb-2">
														<Col lg={4}>
															<h5 className="f-w-500">Date of Birth
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8}>
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.dateofbirth ?
																		AstrologerProfile?.data?.data?.astrologer?.dateofbirth
																		: "none"
																}
															</span>
														</Col>
													</div>
													<div className="d-flex mb-2">
														<Col lg={4} >
															<h5 className="f-w-500">Age
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8} >
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.age ?
																		AstrologerProfile?.data?.data?.astrologer?.age
																		: "none"
																}
															</span>
														</Col>
													</div>
													<div className="d-flex mb-2">
														<Col lg={4} >
															<h5 className="f-w-500">  Gender
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8} >
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.gender ?
																		AstrologerProfile?.data?.data?.astrologer?.gender
																		: "none"
																}
															</span>
														</Col>
													</div>
													<div className="d-flex mb-2">
														<Col lg={4} >
															<h5 className="f-w-500">  Marital Status
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8} >
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.maritalstatus ?
																		AstrologerProfile?.data?.data?.astrologer?.maritalstatus :
																		"none"
																}
															</span>
														</Col>
													</div>
													<div className="d-flex mb-2">
														<Col lg={4} >
															<h5 className="f-w-500">Year Experience
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8}>
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.experience ?
																		AstrologerProfile?.data?.data?.astrologer?.experience
																		: "0"
																}
															</span>
														</Col>
													</div>
													<div className="d-flex mb-2">
														<Col lg={4} >
															<h5 className="f-w-500">  Profession
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8} >
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.profession ?
																		AstrologerProfile?.data?.data?.astrologer?.profession
																		: "none"
																}
															</span>
														</Col>
													</div>
													<div className="d-flex mb-2">
														<Col lg={4} >
															<h5 className="f-w-500">  Qualification
																<span className="pull-right">:</span>
															</h5>
														</Col>
														<Col lg={8} >
															<span>
																{
																	AstrologerProfile?.data?.data?.astrologer?.qualification ?
																		AstrologerProfile?.data?.data?.astrologer?.qualification
																		: "none"
																}
															</span>
														</Col>
													</div>

												</Col>
												<Col xl={7} sm={6}>
													<div className="profile-contact-info">
														<h4 className="text-primary mb-4">
															Contact Information
														</h4>
														<div className="d-flex mb-2">
															<Col lg={3}>
																<h5 className="f-w-500">Email
																	<span className="pull-right">:</span>
																</h5>
															</Col>
															<Col lg={8}>
																<span>
																	{
																		AstrologerProfile?.data?.data?.astrologer?.email ?
																			AstrologerProfile?.data?.data?.astrologer?.email
																			: "none"
																	}
																</span>
															</Col>
														</div>
														<div className="d-flex mb-2">
															<Col lg={3} >
																<h5 className="f-w-500"> Phone Number
																	<span className="pull-right">:</span>
																</h5>
															</Col>
															<Col lg={8} >
																<span>+
																	{
																		AstrologerProfile?.data?.data?.astrologer?.phone ?
																			AstrologerProfile?.data?.data?.astrologer?.phone
																			: "none"
																	}
																</span>
															</Col>
														</div>
													</div>
													<div className="profile-skills mb-4">
														<h4 className="mb-2">Areas of Expertise</h4>
														{description ?
															<>
																{
																	description?.data?.map((expertise, index) => (
																		<Link to="#" className="btn btn-primary light btn-xs mb-1 me-1"> {expertise?.name}</Link>
																	))
																}
															</>
															: "none"}


													</div>
													<div className="profile-lang  mb-4">
														<h4 className="mb-2">Language</h4>
														{language ?
															<>
																{
																	language?.data?.map((language, index) => (
																		<Link to="#" className="light btn-xs mb-1 me-1"> {language?.name}</Link>
																	))
																}
															</>
															: "none"}

													</div>
												</Col>

											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AppProfile;
