import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';
import ToggleSwitch from './ToggleSwitch';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetOffers, ActiveOrDeactiveOffer, successMessage, errorMessage } from '../../../Redux/Slice';

function OfferList() {
    const dispatch = useDispatch();
    const token = localStorage.getItem("astrologer-auth-token");

    const { Offers, success, errors } = useSelector((state) => state.Astromind);

    const [id, setId] = useState();

    useEffect(() => {
        dispatch(GetOffers({ token }));
    }, [dispatch, token])

    const handleChangeToggle = (value) => {
        let status = value ? 1 : 0;
        dispatch(ActiveOrDeactiveOffer({ token, offerId: id, status }))
    };

    useEffect(() => {
        if (success?.status === 200) {
            dispatch(GetOffers({ token }));
            Swal.fire({
                title: success?.data?.message,
                icon: "success",
                iconColor: "#097500",
                confirmButtonColor: "#097500",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then((result) => {
                dispatch(successMessage(''));
            })
        }
        else if (errors?.status === 400) {
            dispatch(GetOffers({ token }));
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000,
            }).then((result) => {
                dispatch(errorMessage(''));
            })
        }
    }, [dispatch, token, success, errors]);

    return (
        <>
            <MainPagetitle pageTitle="Offers" parentTitle="Home" />
            <Row className='margin-3'>
                <Col lg={12} className="my-3">
                    <h2>Available offer</h2>
                </Col>
                {
                    Offers?.data?.data?.offertoall?.length > 0 || Offers?.data?.data?.myoffers?.length > 0 ?
                        <>
                            {
                                Offers?.data?.data?.offertoall?.length > 0 ?
                                    <Col lg={12}>
                                        <div>
                                            <h4>Offer To All</h4>
                                        </div>

                                        <Col lg={12} className='d-flex flex-wrap justify-content-between offer-list'>
                                            {
                                                Offers?.data?.data?.offertoall?.map((offer) => {
                                                    let validityDate = new Date(offer?.validityperoid).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                                                    return (
                                                        <>
                                                            <Col lg={6} className='offers-card'>
                                                                <Col lg={12} sm={12} className='offer-inside-card'>
                                                                    <Col lg={5} sm={5}>
                                                                        <div>
                                                                            <p className='discount-name'> {offer?.name}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className='discount-percentage'>{offer?.discountpercentage} %</p>
                                                                            <p className='discount-name'>Discount</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>Validity Period : </p>
                                                                            <p className='validity-date'>{validityDate}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} sm={7}>
                                                                        <Col lg={12} sm={12} className="d-flex justify-content-between">
                                                                            <Col lg={8} sm={8}>
                                                                                <p><span>User Type :</span> {offer?.usertype}</p>
                                                                            </Col>
                                                                            <Col lg={4} sm={4}>
                                                                                <div className='enable-btn' onClick={() => setId(offer?.id)}>
                                                                                    <ToggleSwitch
                                                                                        activeStatus={offer?.isActivated}
                                                                                        onToggle={handleChangeToggle}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Col>

                                                                        <div>
                                                                            <p><span>Your share :</span> ₹ {offer?.astrologersharepermin} /min </p>
                                                                            <p><span>AMC Share :</span> ₹ {offer?.amcsharepermin} /min </p>
                                                                            <p><span>Customer Pay :</span> ₹ {offer?.userpayspermin} /min  </p>
                                                                        </div>
                                                                        <div>
                                                                            <p className='description-title'>Description</p>
                                                                            <ul className="terms">
                                                                                <li>{offer?.termsandconditions} </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Col>
                                    : null
                            }
                            {
                                Offers?.data?.data?.myoffers?.length > 0 ?
                                    <Col lg={12}>
                                        <div>
                                            <h4>Offer for You</h4>
                                        </div>

                                        <Col lg={12} className='d-flex flex-wrap justify-content-between offer-list'>
                                            {
                                                Offers?.data?.data?.myoffers?.map((offer) => {
                                                    let validityDate = new Date(offer?.validityperoid).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
                                                    return (
                                                        <>
                                                            <Col lg={6} className='offers-card'>
                                                                <Col lg={12} sm={12} className='offer-inside-card'>
                                                                    <Col lg={5} sm={5}>
                                                                        <div>
                                                                            <p className='discount-name'> {offer?.name}</p>
                                                                        </div>
                                                                        <div>
                                                                            <p className='discount-percentage'>{offer?.discountpercentage} %</p>
                                                                            <p className='discount-name'>Discount</p>
                                                                        </div>
                                                                        <div>
                                                                            <p>Validity Period : </p>
                                                                            <p className='validity-date'>{validityDate}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={7} sm={7}>
                                                                        <Col lg={12} sm={12} className="d-flex justify-content-between">
                                                                            <Col lg={8} sm={8}>
                                                                                <p><span>User Type :</span> {offer?.usertype}</p>
                                                                            </Col>
                                                                            <Col lg={4} sm={4}>
                                                                                <div className='enable-btn' onClick={() => setId(offer?.id)}>
                                                                                    <ToggleSwitch
                                                                                        activeStatus={offer?.isActivated}
                                                                                        onToggle={handleChangeToggle}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </Col>

                                                                        <div>
                                                                            <p><span>Your share :</span> ₹ {offer?.astrologersharepermin} /min </p>
                                                                            <p><span>AMC Share :</span> ₹ {offer?.amcsharepermin} /min </p>
                                                                            <p><span>Customer Pay :</span> ₹ {offer?.userpayspermin} /min  </p>
                                                                        </div>
                                                                        <div>
                                                                            <p className='description-title'>Description</p>
                                                                            <ul className="terms">
                                                                                <li>{offer?.termsandconditions} </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Col>
                                    : null
                            }
                        </>
                        :
                        <div className='no-data'>
                            <p>No sufficient data available!</p>
                        </div>
                }

            </Row >
        </>
    )
}

export default OfferList;