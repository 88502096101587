import { Col } from 'react-bootstrap';
import { FaCaretRight } from "react-icons/fa";
import { MdFileDownload } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";

import YoutubeIcon from '../../../../images/dashboard/youtube-icon.png'
import CourselistIcon from '../../../../images/dashboard/course-list-icon.png'

const WidgetCard = () => {
    return (
        <>
            <a href="/training-videos/">
                <Col lg={12} sm={6} className='widget-card' >
                    <div className='d-flex gap-3'>
                        <div>
                            <img src={YoutubeIcon} alt="" />
                        </div>
                        <div>
                            <p>Training Videos</p>
                        </div>
                    </div>

                    <div className='d-flex align-items-center'>
                        <FaCaretRight className='click-icon' />
                    </div>
                </Col>
            </a>

            <Col lg={12} sm={6} className='widget-card'>
                <div className='d-flex gap-3'>
                    <div>
                        <img src={CourselistIcon} alt="" />
                    </div>
                    <div>
                        <p>Course Details</p>
                    </div>
                </div>

                <div className='d-flex align-items-center'>
                    <FaCaretRight className='click-icon' />
                </div>
            </Col>

            <Col lg={12} sm={6} className='widget-card' >
                <div className='d-flex gap-3'>
                    <div>
                        {/* <img src={CourselistIcon} alt="" /> */}
                        <FaFilePdf className='file-icon' />
                    </div>
                    <div>
                        <p>Engagement Letter</p>
                    </div>
                </div>

                <div className='d-flex align-items-center'>
                    <a href="https://astromind-prod.s3.ap-south-1.amazonaws.com/Astromind-Counsel-Astrologer-Engagement-Letter.pdf">
                        <MdFileDownload className='click-icon' />
                    </a>
                </div>
            </Col>
        </>
    )
}

export default WidgetCard;