

import { Col } from 'react-bootstrap';


function DashaPeriodDetails({ list }) {
    function formatDateTime(inputDate) {
        const [datePart, timePart] = inputDate.split('  ');
        const [day, month, year] = datePart.split('-').map(Number);
        const [hours, minutes] = timePart.split(':').map(Number);

        const date = new Date(year, month - 1, day, hours, minutes);

        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        });

        return `${formattedDate}`;
    }
    return (
        <>
            {list?.waiting?.subuser?.birthtable?.current_vimshottari_dasha?.major?.dasha_period?.length > 0 ?
                <Col lg={12} className='call-list birth-planet mb-3'>
                    <table >
                        <tr>
                            <th>கிரகம்</th>
                            <th >துவக்கம்</th>
                            <th>முடிவு</th>
                        </tr>
                        {
                            list?.waiting?.subuser?.birthtable?.current_vimshottari_dasha?.major?.dasha_period?.map((data, index) => {
                                let startDate = formatDateTime(data?.start);
                                let endDate = formatDateTime(data?.end);
                               
                                return (
                                    <>
                                        <tr>
                                            <td>
                                                {data?.planet == "வியாழன் " ? "குரு" :
                                                    data?.planet == "வெள்ளி " ? "சுக்கிரன்" :
                                                        data?.planet == "கேட்டு" ? "கேது" :
                                                            data?.planet == "களத்திரஸ்தானம்" ? "லக்னம்" :
                                                                data?.planet?.slice(0, 8)}
                                            </td>
                                            <td>
                                                {startDate}
                                            </td>
                                            <td>
                                                {endDate}
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </table>
                </Col>
                :
                <>
                    <div className='no-data'>
                        <p>No sufficient data available!</p>
                    </div>
                </>
            }
        </>
    )
}

export default DashaPeriodDetails;