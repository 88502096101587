import Modal from 'react-bootstrap/Modal';
import { Col } from 'react-bootstrap';

import EmptyProfile from '../../../images/dashboard/empty-profile.png'

function FollowersDetail({ profileShow, profileClose, follower }) {
    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={profileShow}
                onHide={profileClose}
                className='follower-profile'
            >
                <Modal.Header closeButton>
                    <Modal.Title>User Profile Details </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col lg={12} className='d-flex justify-content-between'>
                        <Col lg={5}>
                            <div>
                                <h5>Name </h5>
                                <p>{follower?.name}</p>
                            </div>
                            <div>
                                <h5>Date of Birth</h5>
                                <p>{follower?.dateofbirth}</p>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className='follower-detail-img'>
                                <img src={follower?.files[0]?.file ? follower?.files[0]?.file : EmptyProfile} alt="" className='img-fluid' />
                            </div>
                        </Col>
                    </Col>
                    <Col lg={12} className='d-flex justify-content-between'>
                        <Col lg={6}>
                            <div>
                                <h5>Marital status</h5>
                                <p>{follower?.maritalstatus}</p>
                            </div>
                            <div>
                                <h5>Birth Place</h5>
                                <p>{follower?.placeofbirth}</p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div>
                                <h5>Gender</h5>
                                <p>{follower?.gender}</p>
                            </div>
                            <div>
                                <h5>Birth Time</h5>
                                <p>{follower?.timeofbirth}</p>
                            </div>
                        </Col>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FollowersDetail;