import React,{useContext, useEffect} from 'react';
import {  Col } from 'react-bootstrap';


//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import MainPagetitle from '../../layouts/MainPagetitle';
import OfferCard from './dashboard-elements/OfferCard';
import WidgetCard from './dashboard-elements/WidgetCard'
import StatusCard from './dashboard-elements/StatusCard'

const Home = ({ profile }) => {
	const { changeBackground } = useContext(ThemeContext);	
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);
		
	return(
		<>			
			<MainPagetitle pageTitle="Dashboard" parentTitle="Home"  />
		
		    <div className='bg-lavender p-3 offerMain'>
				<OfferCard />
			</div>

			<Col className='d-flex flex-wrap gap-5 margin-3'>
				<Col lg={4} sm={12} className='widget-card-main' >
					<WidgetCard />
				</Col>
				<Col lg={7} sm={12} className='status-card-main'>
					<StatusCard profile={ profile }  />
				</Col>
			</Col>
		</>
	)
}
export default Home;