import React, { useState, useEffect } from "react";
import { Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { Multiselect } from 'multiselect-react-dropdown';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { UpdateAstrologerProfile } from '../../../../Redux/Slice';

const EditProfile = ({ editShow, editClose, profile }) => {

    const dispatch = useDispatch();
    const { Loading } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("astrologer-auth-token");

    const [astrologerData, setAstrologerData] = useState();
    const [expertiseOption, setExpertiseOption] = useState([{ name: 'Face Reading' }, { name: 'KP' }, { name: 'Life Coach' }, { name: 'Nadi' }, { name: 'Numerology' }, { name: 'Palmistry' }, { name: 'Prashana' }, { name: 'Psychic' }, { name: 'Psychologist' }, { name: 'Tarot' }]);
    const [languageOption, setLanguageOption] = useState([{ name: 'English' }, { name: 'Tamil' }, { name: 'Hindi' }, { "name": 'Telugu' }, { "name": 'Malayalam' }, { "name": 'Kannada' }]);

    useEffect(() => {
        setAstrologerData({
            name: profile?.data?.data?.astrologer?.name,
            gender: profile?.data?.data?.astrologer?.gender,
            age: profile?.data?.data?.astrologer?.age,
            dateofbirth: new Date(profile?.data?.data?.astrologer?.dateofbirth),
            maritalstatus: profile?.data?.data?.astrologer?.maritalstatus,
            experience: profile?.data?.data?.astrologer?.experience,
            email: profile?.data?.data?.astrologer?.email,
            phone: profile?.data?.data?.astrologer?.phone,
            description: profile?.data?.data?.astrologer?.description ? profile?.data?.data?.astrologer?.description?.data : null,
            language: profile?.data?.data?.astrologer?.language ? profile?.data?.data?.astrologer?.language?.data : null,
            qualification: profile?.data?.data?.astrologer?.qualification,
            profession: profile?.data?.data?.astrologer?.profession,
            about: profile?.data?.data?.astrologer?.about
        })
    }, [profile, editShow]);

    const EditClose = (e) => {
        e.preventDefault()
        editClose(false);
    }

    const handleUpdateProfile = (e) => {
        e.preventDefault()
        let description = JSON.stringify({ "data": astrologerData?.description });
        let language = JSON.stringify({ "data": astrologerData?.language });

        let updateData = {
            name: astrologerData?.name,
            gender: astrologerData?.gender,
            age: astrologerData?.age,
            dateofbirth: new Date(astrologerData?.dateofbirth).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }),
            maritalstatus: astrologerData?.maritalstatus,
            experience: astrologerData?.experience,
            email: astrologerData?.email,
            phone: astrologerData?.phone,
            description: description,
            language: language,
            qualification: astrologerData?.qualification,
            profession: astrologerData?.profession,
            about: astrologerData?.about
        }

        if (token) {
            dispatch(UpdateAstrologerProfile({ updateData, token }));
        }
    }

    return (
        <>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={editShow}
                onHide={EditClose}
                className=''
            >
                <Modal.Body>
                    <form action="" >
                        <div>
                            <h2 className="text-center">Edit Your Basic Details</h2>
                        </div>
                        <div className={"details__wrapper register-form"}>
                            <Col lg={6} className="p-3">
                                <div className="mb-4">
                                    <h3>Personal Details</h3>
                                </div>
                                <div className="form__item  mb-4">
                                    <label>Enter your Name <span className="text-red">*</span></label>
                                    <input
                                        name="name"
                                        className="px-3"
                                        value={astrologerData?.name}
                                        onChange={(e) => { setAstrologerData({ ...astrologerData, name: e.target.value }) }}
                                    />
                                </div>
                                <div className="form__item  mb-4">
                                    <label>Enter your Email<span className="text-red">*</span> </label>
                                    <input
                                        name="email"
                                        className="px-3"
                                        value={astrologerData?.email}
                                        onChange={(e) => { setAstrologerData({ ...astrologerData, email: e.target.value }) }}
                                    />
                                </div>
                                <div className="form__item mb-4">
                                    <label>Phone Number <span className="text-red">*</span></label>
                                    <PhoneInput
                                        country={'in'}
                                        id='phone'
                                        name='phone'
                                        value={astrologerData?.phone}
                                        onChange={(value) => { setAstrologerData({ ...astrologerData, phone: value }) }}
                                    />
                                </div>
                                <div className="d-flex gap-3 mb-3">
                                    <div className="form__item half-form">
                                        <label>Date Of Birth </label>
                                        <DatePicker
                                            className="px-3"
                                            showIcon
                                            selected={astrologerData?.dateofbirth}
                                            onChange={(date) => { setAstrologerData({ ...astrologerData, dateofbirth: date }) }}
                                            monthsShown={1}
                                            showYearDropdown
                                        />
                                    </div>
                                    <div className="form__item half-form">
                                        <label>Age</label>
                                        <input
                                            type="number"
                                            name="age"
                                            className="px-3"
                                            value={astrologerData?.age}
                                            onChange={(e) => { setAstrologerData({ ...astrologerData, age: e.target.value }) }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex gap-3 ">
                                    <div
                                        className="form__item half-form"
                                    >
                                        <label>Gender </label>
                                        <select
                                            name="gender"
                                            className="px-2"
                                            defaultValue={astrologerData?.gender}
                                            value={astrologerData?.gender}
                                            onChange={(e) => { setAstrologerData({ ...astrologerData, gender: e.target.value }) }}
                                        >
                                            <option value="">Choose</option>
                                            <option value="male"  >Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                    <div className="form__item half-form">
                                        <label>Marital Status </label>
                                        <select
                                            name="maritalstatus"
                                            className="px-2"
                                            defaultValue={astrologerData?.maritalstatus}
                                            value={astrologerData?.maritalstatus}
                                            onChange={(e) => { setAstrologerData({ ...astrologerData, maritalstatus: e.target.value }) }}
                                        >
                                            <option value="">Choose</option>
                                            <option value="Single">Single</option>
                                            <option value="Married">Married</option>
                                            <option value="Divorced">Divorced</option>
                                        </select>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} className="p-3">
                                <div className="mb-4">
                                    <h3>
                                        Professional Details
                                    </h3>
                                </div>
                                <div className="mb-4" >
                                    <label>Areas of Expertise <span className="text-red">*</span></label>
                                    <Multiselect
                                        options={expertiseOption}
                                        selectedValues={astrologerData?.description}
                                        onSelect={(value) => { setAstrologerData({ ...astrologerData, description: value }) }}
                                        displayValue="name"
                                    />
                                </div>
                                <div className="mb-4" >
                                    <label>Languages <span className="text-red">*</span></label>
                                    <Multiselect
                                        options={languageOption}
                                        selectedValues={astrologerData?.language}
                                        onSelect={(value) => { setAstrologerData({ ...astrologerData, language: value }) }}
                                        displayValue="name"
                                    />
                                </div>
                                <div className="mb-4" >
                                    <label>Experience <span className="text-red">*</span></label>
                                    <input
                                        type="number"
                                        name="exprience"
                                        className="px-3"
                                        value={astrologerData?.experience}
                                        onChange={(e) => { setAstrologerData({ ...astrologerData, experience: e.target.value }) }}
                                    />
                                </div>
                                <div className="mb-4" >
                                    <label>Qualification </label>
                                    <input
                                        type="text"
                                        name="qualification"
                                        className="px-3"
                                        value={astrologerData?.qualification}
                                        onChange={(e) => { setAstrologerData({ ...astrologerData, qualification: e.target.value }) }}
                                    />
                                </div>
                                <div className="form__item mb-4">
                                    <label>Profession </label>
                                    <input
                                        type="text"
                                        name="profession"
                                        className="px-3"
                                        value={astrologerData?.profession}
                                        onChange={(e) => { setAstrologerData({ ...astrologerData, profession: e.target.value }) }}
                                    />
                                </div>
                            </Col>
                            <Col lg={12} sm={12} className="px-3">
                                <div className="form__item ">
                                    <label>Who I Am</label>
                                    <textarea
                                        type="text"
                                        name="about"
                                        className="p-3"
                                        value={astrologerData?.about}
                                        onChange={(e) => { setAstrologerData({ ...astrologerData, about: e.target.value }) }}
                                    />
                                </div>
                            </Col>
                            <div className={"form__item2 button__items d-flex justify-content-center mx-4"} >
                                <div className='d-flex justify-content-center gap-3 pt-3 pb-3'>
                                    <button
                                        className={Loading ? 'otp-button bg-yellow text-white disabled' : 'otp-button bg-yellow text-white'}
                                        onClick={(e) => handleUpdateProfile(e)}
                                    >
                                        {Loading ? <div className='loader'></div> : "Submit"}
                                    </button>
                                    <button
                                        onClick={(e) => EditClose(e)}
                                        className='otp-button btn-primary text-white'
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default EditProfile;
