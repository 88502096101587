import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import StatusSuccess from '../../../images/dashboard/status-success.png';
import StatusCancel from '../../../images/dashboard/status-cancel.png';

import MainPagetitle from '../../layouts/MainPagetitle';
import OrderHistoryPreview from './OrderHistoryPreview';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetOrderHistory } from '../../../Redux/Slice';

const OrderHistoryList = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("astrologer-auth-token");
    const { OrderHistory } = useSelector((state) => state.Astromind);
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const [show, setShow] = useState(false);
    const [type, setType] = useState();
    const [previewData, setPreviewData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const pageLimit = window.innerWidth < 600 ? 15 : 25;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        let page = currentPage ? currentPage : 1;;
        if (pathname == "/call-logs/") {
            setType("call");
            dispatch(GetOrderHistory({ token, page, pageLimit, type: "call" }))
        }

        else if (pathname == "/chat-logs/") {
            setType("chat");
            dispatch(GetOrderHistory({ token, page, pageLimit, type: "chat" }))

        }
    }, [pathname, type]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (OrderHistory?.data?.pagination?.currentpage) {
            setCurrentPage(OrderHistory?.data?.pagination?.currentpage);
        }
    }, [OrderHistory]);

    const PreviewCall = (data) => {
        setPreviewData(data);
        handleShow();
    }

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        dispatch(GetOrderHistory({ token, page, pageLimit, type }));
    }

    return (
        <>
            <MainPagetitle
                pageTitle={type == "call" ? "Call Logs" : "Chat Logs"}
                parentTitle="Order History"
            />
            <Container fluid>
                <Row className='d-flex'>
                    <Col lg={12} className='call-list-main'>
                        <div className='call-list-title'>
                            <h3 className='fw-600'>
                                {type == "call" ? "Call Logs" : "Chat Logs"}
                            </h3>
                        </div>
                        {OrderHistory?.data?.data?.length > 0 ?
                            <Col lg={12} className='call-list mb-3'>
                                <table className={show ? "table-60" : "table-100"} >
                                    <tr>
                                        <th>S No</th>
                                        <th>Date & Time</th>
                                        <th>Order ID</th>
                                        <th className='mobile-hide'>Name</th>
                                        <th className={show ? "hide-list" : null}>Duration</th>
                                        {/* <th className={show ? "hide-list mobile-hide" : "mobile-hide"}>Total Rate</th> */}
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                    {OrderHistory?.data?.data?.map((data, index) => {
                                        let indianTime = new Date(data?.order?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });

                                        let Minute = data?.order?.type == "call" ?
                                            Math.floor(data?.order?.calls[0]?.ConversationDuration / 60)
                                            : data?.order?.type == "chat" ?
                                                Math.floor(data?.chat[0]?.duration / 60)
                                                : 0;

                                        let seconds = data?.order?.type == "call" ?
                                            data?.order?.calls[0]?.ConversationDuration % 60
                                            : data?.order?.type == "chat" ?
                                                Math.floor(data?.chat[0]?.duration % 60)
                                                : 0;

                                        return (
                                            <>
                                                <tr>
                                                    <td>
                                                        {
                                                            OrderHistory?.data?.pagination?.currentpage === 1 ?
                                                                <>
                                                                    {index + 1}
                                                                </>
                                                                :
                                                                <>
                                                                    {((OrderHistory?.data?.pagination?.currentpage - 1) * pageLimit) + (index + 1)}
                                                                </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {indianTime}
                                                    </td>
                                                    <td>
                                                        {data?.order?.id}
                                                    </td>
                                                    <td className='mobile-hide'>
                                                        {data?.order?.subuser?.name}
                                                    </td>
                                                    <td className={show ? "hide-list" : null}>
                                                        {data?.order?.calls?.length > 0 ? <>{`${Minute} min ${seconds} sec`}</> : data?.chat?.length > 0 ? <>{`${Minute} min ${seconds} sec`}</> : "none"}</td>
                                                    {data?.order?.type == "call" ?
                                                        <td>{data?.order?.calls[0]?.Status == 'completed' ? <img src={StatusSuccess} alt='success' /> : <img src={StatusCancel} alt='cancel' />}</td>
                                                        : data?.order?.type == "chat" ?
                                                            <td>{data?.chat[0]?.status == 'completed' ? <img src={StatusSuccess} alt='success' /> : <img src={StatusCancel} alt='cancel' />}</td>
                                                            : null
                                                    }
                                                    <td className='view' onClick={() => PreviewCall(data)}>view details</td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </table>
                            </Col>
                            :
                            <>
                                <div className='no-data'>
                                    <p>No sufficient data available!</p>
                                </div>
                            </>
                        }
                        <OrderHistoryPreview show={show} handleClose={handleClose} data={previewData} />
                    </Col>
                    {OrderHistory?.data?.pagination?.totalPage > 1 ?
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={OrderHistory?.data?.pagination?.totalPage}
                            forcePage={OrderHistory?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                        : null
                    }
                </Row>
            </Container>
        </>
    )
}

export default OrderHistoryList;