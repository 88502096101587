import { Container, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import { AiFillStar } from "react-icons/ai";
import ProgressBar from 'react-bootstrap/ProgressBar';
import EmptyProfile from '../../../images/dashboard/empty-profile.png'
// import KaParthiban from '../../../Assets/AstrologerImages/Ka-Parthiban-astrologer.png'

import MainPagetitle from '../../layouts/MainPagetitle';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetReviews } from '../../../Redux/Slice';



function ReviewsList() {
    const dispatch = useDispatch();
    const { UserReviews } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem("astrologer-auth-token");

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        let page = currentPage ? currentPage : 1;;

        dispatch(GetReviews({ token, page }))
    }, [dispatch, token, currentPage])

    useEffect(() => {
        window.scrollTo(0, 0);
        if (UserReviews?.data?.data?.pagination?.currentpage) {
            setCurrentPage(UserReviews?.data?.data?.pagination?.currentpage);
        }
    }, [UserReviews]);

    const changePage = (value) => {
        let page = value;
        dispatch(GetReviews({ token, page }));
    }

    const nextPage = () => {
        const page = Number(currentPage) + 1;
        if (page <= UserReviews?.data?.data?.pagination?.totalPage) {
            dispatch(GetReviews({ token, page }));
        }
    }

    const previousPage = () => {
        const page = Number(currentPage) - 1;
        if (page >= 1) {
            dispatch(GetReviews({ token, page }));
        }
    }

    return (
        <>
            <MainPagetitle pageTitle="Reviews and Ratings" parentTitle="Home" />

            <Container fluid className='RatingwMain mb-5'>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={12} className='mb-3'>
                            <h3>Rating & Reviews</h3>
                        </Col>
                        <Col lg={8} className='RatingCard'>
                            <h4>Ratings</h4>
                            <Col lg={12} className='d-flex'>
                                <Col lg={3}>
                                    <p className='RatingAverage'>{UserReviews?.data?.data?.count?.averageRating}</p>
                                    <p className='mb-0'>({UserReviews?.data?.data?.count?.totalReviews})</p>
                                    <div className='profileStars'>
                                        <div className="profileStar">
                                            <span>
                                                {[...new Array(5)].map((arr, index) => {
                                                    return index < `${UserReviews?.data?.data?.count?.avg}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={8}>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>5</h5>
                                        </div>
                                        <div className="progress progress1">
                                            <ProgressBar now={UserReviews?.data?.data?.count?.five} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>4</h5>
                                        </div>
                                        <div className="progress progress2">
                                            <ProgressBar now={UserReviews?.data?.data?.count?.four} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>3</h5>
                                        </div>
                                        <div className="progress progress3">
                                            <ProgressBar now={UserReviews?.data?.data?.count?.three} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>2</h5>
                                        </div>
                                        <div className="progress progress4">
                                            <ProgressBar now={UserReviews?.data?.data?.count?.two} />
                                        </div>
                                    </Col>
                                    <Col lg={12} className='d-flex align-items-center gap-3'>
                                        <div>
                                            <h5>1</h5>
                                        </div>
                                        <div className="progress progress5">
                                            <ProgressBar now={UserReviews?.data?.data?.count?.one} />
                                        </div>
                                    </Col>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className='ReviewMain'>
                <Container>
                    <Row>
                        <Col lg={12} className='Reviews-heading text-white p-2 px-4'>
                            <h3>User Review</h3>
                        </Col>
                        <Col lg={12} className='user-reviews'>
                            {
                                UserReviews?.data?.data?.reviews?.length > 0 ?
                                    <>
                                        {UserReviews?.data?.data?.reviews?.map((reviews, i) => {
                                            let indianTime = new Date(reviews?.review?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                            return (
                                                <>
                                                    <Col lg={12} className='review-card mt-3'>
                                                        <Col lg={3} sm={4} className='reviewer_profile'>
                                                            <div className='d-flex justify-content-center profileImg'>
                                                                <img src={reviews?.profile ? reviews?.profile?.file : EmptyProfile} alt="" />
                                                            </div>
                                                            <div className='reviewer_detail'>
                                                                <div>
                                                                    <h5>{reviews?.review?.user?.name}</h5>
                                                                </div>
                                                                <div className='profileStars'>
                                                                    <div className="profileStar">
                                                                        <span>
                                                                            {[...new Array(5)].map((arr, index) => {
                                                                                return index < `${reviews?.review?.rating}` ? <AiFillStar className="colorStar" key={index} /> : <AiFillStar key={index} className="NoColorStar" />;
                                                                            })}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='review-date'>
                                                                    <p>{indianTime}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col lg={9} sm={8} className='review_comment'>
                                                            <div>
                                                                <p>{reviews?.review?.comment}</p>
                                                            </div>
                                                            {/* <div className='bg-lightOrange astrologer-command px-3 py-2'>
                                                            <div>
                                                                <p>{astro.name}</p>
                                                                <p>{review.astrologer_command}</p>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </div> */}
                                                        </Col>
                                                    </Col >
                                                </>
                                            )
                                        })}
                                    </>
                                    :
                                    <div className='no-data'>
                                        <p>No sufficient data available!</p>
                                    </div>
                            }
                        </Col>
                        {UserReviews?.data?.data?.pagination?.totalPage > 1 ?
                            <Col lg={12} className='d-flex justify-content-center mt-3 pagination'>
                                <Pagination className="px-4">
                                    <Pagination.Prev onClick={() => previousPage()} />

                                    {[...new Array(UserReviews?.data?.data?.pagination?.totalPage)].map((arr, index) => {
                                        return (
                                            <Pagination.Item
                                                onClick={() => changePage(index + 1)}
                                                key={index + 1}
                                                active={index + 1 === Number(currentPage)}
                                            >
                                                {index + 1}
                                            </Pagination.Item>
                                        )
                                    })}
                                    <Pagination.Next onClick={() => nextPage()} />
                                </Pagination>
                            </Col>
                            : null
                        }
                    </Row >
                </Container>
            </Container>
        </>
    )
}

export default ReviewsList;