import { Row, Col } from 'react-bootstrap';
import MainPagetitle from '../../layouts/MainPagetitle';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetMyWallet, GetMyEarnings } from '../../../Redux/Slice';

const Earnings = () => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("astrologer-auth-token");
    const { WalletAmount, EarningsHistory } = useSelector((state) => state.Astromind);

    let page = 1;
    const [currentPage, setCurrentPage] = useState(1);
    const [balance, setBalance] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(EarningsHistory?.data?.pagination?.currentpage);
    }, [EarningsHistory]);

    useEffect(() => {
        dispatch(GetMyEarnings({ token, page }));
        dispatch(GetMyWallet(token));
    }, [dispatch, token, page]);

    const handlePageClick = (event) => {
        const page = Number(event.selected) + 1;
        dispatch(GetMyEarnings({ token, page }))
    }

    useEffect(() => {
        let availableBalance = Number(WalletAmount?.data?.data?.mywallet);
        // let pgCharge = (availableBalance * 2.5) / 100;
        // let subTotal = availableBalance - pgCharge;
        let tdsCharge = (availableBalance * 10) / 100;
        let gstCharge = 0;
        let payableAmount = availableBalance - tdsCharge + gstCharge;

        setBalance({
            availableBalance: availableBalance.toFixed(2),
            // pgCharge: pgCharge.toFixed(2),
            tdsCharge: tdsCharge.toFixed(2),
            gstCharge: gstCharge.toFixed(2),
            payableAmount: payableAmount.toFixed(2),
        });
    }, [WalletAmount]);

    return (
        <>
            <MainPagetitle pageTitle="Earnings" parentTitle="Home" />

            <Row className='d-flex justify-content-center margin-3' >
                <Col lg={12} sm={12} className='blance-card'>
                    <Col className='text-center'>
                        <p className='avail-balance'><span>Available Balance :</span> ₹ {balance?.availableBalance ? balance?.availableBalance : '0.00'}</p>
                    </Col>
                    <Col className='d-flex flex-wrap justify-content-center gap-3 mt-3'>
                        {/* <Col lg={3} sm={5}>
                            <p><span>PG Charge  :</span> ₹ {balance?.pgCharge ? balance?.pgCharge : '0.00'}</p>
                            </Col> */}
                        <Col lg={3} sm={5}>
                            <p><span>TDS : </span> ₹ {balance?.tdsCharge ? balance?.tdsCharge : '0.00'}</p></Col>
                        <Col lg={3} sm={5}>
                            <p><span>GST : </span> ₹ {balance?.gstCharge ? balance?.gstCharge : '0.00'}</p></Col>
                        <Col lg={4} sm={5}>
                            <p >Payable Amount : <span className='fw-bold text-black'> ₹ {balance?.payableAmount ? balance?.payableAmount : '0.00'}</span></p>
                        </Col>
                    </Col>
                </Col>
                <Col lg={12} className='transaction-table mt-5'>
                    {EarningsHistory?.data?.data?.length > 0 ?
                        <>
                            <table>
                                <tr className='transaction-heading'>
                                    <th>Date & Time</th>
                                    <th>Order Id</th>
                                    <th>User Name</th>
                                    <th>Duration</th>
                                    <th>Type</th>
                                    <th>Total Amount</th>
                                    <th>Your Share</th>
                                </tr>
                                {EarningsHistory?.data?.data?.map((data, i) => {
                                    let indianTime = new Date(data?.createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
                                    let Minute = Math.floor(data.duration / 60);
                                    const seconds = data.duration % 60;
                                    return (
                                        <>
                                            <tr className='transaction-data'>
                                                <td>{indianTime}</td>
                                                <td>{data.id}</td>
                                                <td>{data.user_name}</td>
                                                <td>{Minute} min {seconds} sec</td>
                                                <td>{data.type}</td>
                                                <td className='payment-success' ><span className='text-black'>₹</span> + {data.totalrate}</td>
                                                <td className='payment-success' ><span className='text-black'>₹</span> + {data.astrologer_share_cost}</td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </table>
                        </>
                        :
                        <>
                            <div className='no-data'>
                                <p>No sufficient data available!</p>
                            </div>
                        </>
                    }
                </Col>

                {EarningsHistory?.data?.pagination?.totalPage > 1 ?
                    <>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={EarningsHistory?.data?.pagination?.totalPage}
                            forcePage={EarningsHistory?.data?.pagination?.currentpage - 1}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            className='custom-pagination'
                        />
                    </ >
                    :
                    null
                }
            </Row>
        </>
    );
};

export default Earnings;