import { useState, useEffect } from 'react';

const ToggleSwitch = ({ activeStatus, label, onToggle }) => {
    const [isChecked, setIsChecked] = useState(activeStatus);

    useEffect(() => {
        setIsChecked(activeStatus);
    }, [activeStatus]);

    const handleToggle = () => {
        setIsChecked(!isChecked);
        if (onToggle) {
            onToggle(!isChecked);
        }
    };

    return (
        <label className="toggle-switch offer-switch">
            {label && <span className="label-text">{label}</span>}
            <input
                type="checkbox"
                checked={isChecked}
                onChange={handleToggle}
            />
            <span className="switch" >
                <span className="switch-text">{isChecked ? 'On' : 'Off'}</span>
            </span>
        </label>
    );
};

export default ToggleSwitch;