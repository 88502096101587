import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import useInterval from 'use-interval';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import EmptyProfile from '../../../images/dashboard/empty-profile.png';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetWaitingList, errorMessage, CallStatusMessage, AcceptOrRejectCall } from '../../../Redux/Slice';

import MainPagetitle from '../../layouts/MainPagetitle';
import BirthChart from './BirthChart';
import PlanetDetails from './PlanetDetails';
import DashaPeriodDetails from './DashaPeriodDetails'
import CallConnect from './CallConnect';
import ChatConnect from './ChatConnect';

const WaitListDetails = ({ socket }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { WaitingList, CallProcess, errors, Loading } = useSelector((state) => state.Astromind);

    // const { id } = useParams();
    const token = localStorage.getItem("astrologer-auth-token");
    const id = WaitingList?.data?.message == "No Waiting Data" ? null : WaitingList?.data?.data?.waiting?.id;
    let orderId = localStorage.getItem("orderId");
    let roomName = localStorage.getItem("roomName");

    const [waitingList, setWaitingList] = useState();
    const [showConnect, setShowConnect] = useState(false);
    const [chatConnect, setChatConnect] = useState(false);
    const [showNotes, setShowNotes] = useState(false);

    useEffect(() => {
        dispatch(GetWaitingList(token));
    }, [dispatch, token]);

    useEffect(() => {
        if (roomName != undefined) {
            setChatConnect(true);
        }
    }, [roomName]);

    useInterval(() => {
        if (!orderId) {
            localStorage.removeItem("orderId");
            dispatch(GetWaitingList(token));
        }
        if (orderId) {
            setShowConnect(true);
            // setShowNotes(true);
        }
        if (roomName != undefined) {
            setChatConnect(true);
        }
    }, 10000);

    useEffect(() => {
        if (id) {
            // const List = WaitingList?.data?.data?.find((item) => item?.waiting?.id == id);
            setWaitingList(WaitingList?.data?.data);
        }
    }, [WaitingList, id]);

    const handleConnect = () => {
        let status = 1;
        let waitingId = waitingList?.waiting?.id;
        if (waitingList?.waiting?.type == "call") {
            localStorage.setItem("orderId", waitingList?.waiting?.orderId);
            dispatch(AcceptOrRejectCall({ token, status, waitingId, type: waitingList?.waiting?.type }));
        }
        else if (waitingList?.waiting?.type == "chat") {
            // setChatConnect(true);
            dispatch(AcceptOrRejectCall({ token, status, waitingId, type: waitingList?.waiting?.type }));
            localStorage.setItem("chatOrderId", waitingList?.waiting?.orderId);
        }
    }

    const handleReject = () => {
        let status = 0;
        let waitingId = waitingList?.waiting?.id;
        dispatch(AcceptOrRejectCall({ token, status, waitingId, type: waitingList?.waiting?.type }));
    }
    const notesClose = (value) => {
        setShowNotes(value);
    }
    const callClose = (value) => {
        setShowConnect(value);
        setShowNotes(value);
        dispatch(GetWaitingList(token));

        if (WaitingList?.data?.message == "No Waiting Data") {
            window.location.reload(false);
        }
    }

    const chatClose = (value) => {
        setChatConnect(value)
    }

    useEffect(() => {
        // if(Loading){
        //     Swal.fire({
        //         title: "Please Wait....",
        //     })
        // }
        if (CallProcess?.status === 200) {
            if (CallProcess?.data?.data?.Rejected) {
                Swal.fire({
                    title: CallProcess?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "OK"
                }).then((result) => {
                    if (result?.isConfirmed) {
                        dispatch(CallStatusMessage({ CallProcess: '' }));
                        window.location.reload(false);
                    }
                })

            }
            else if (CallProcess?.data?.data?.callData?.chatIsStarted) {
                localStorage.setItem("roomName", CallProcess?.data?.data?.callData?.roomName);
                localStorage.setItem("userId", CallProcess?.data?.data?.callData?.userId);
                localStorage.setItem("messageSessionId", CallProcess?.data?.data?.callData?.chatSessionId);
                setChatConnect(true);
                // window.location.reload(false);
            }
            else {
                setShowConnect(true);
                // setShowNotes(true);
            }

        }
        else if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay"
            }).then((result) => {
                if (result?.isConfirmed) {
                    dispatch(errorMessage({ errors: '' }));
                }
            })
        }

    }, [CallProcess, errors, Loading, dispatch, navigate]);


    return (
        <>
            <MainPagetitle pageTitle="Work Desk" pageTitleSub="Details" parentTitle="Order History" />

            {
                chatConnect ?
                    <ChatConnect
                        socket={socket}
                        showConnect={chatConnect}
                        chatClose={chatClose}
                        showNotes={showNotes}
                        waitingList={waitingList}
                    />
                    : null
            }
            {
                orderId && waitingList?.waiting?.type == "call" ?
                    <CallConnect
                        showConnect={showConnect}
                        showNotes={showNotes}
                        callClose={callClose}
                        notesClose={notesClose}
                        waitingList={waitingList}
                    />
                    : null
            }
            {
                id ?
                    <Row className='d-flex justify-content-center margin-3'>
                        <Col lg={12} className='waiting-details-btn'>
                            <button className='waiting-details-type' >
                                {
                                    waitingList?.waiting?.type == "call" ?
                                        "Call"
                                        : waitingList?.waiting?.type == "chat" ?
                                            "Chat"
                                            : null
                                }
                            </button>
                            {
                                showConnect ?
                                    null :
                                    <div className='d-flex gap-3'>
                                        <button onClick={() => { handleConnect() }} >
                                            {
                                                Loading ?
                                                    <div className='loader'></div>
                                                    : waitingList?.waiting?.type == "call" ?
                                                        "Start Call"
                                                        : waitingList?.waiting?.type == "chat" ?
                                                            "Start Chat"
                                                            : null
                                            }
                                        </button>
                                        <button onClick={() => { handleReject() }} >
                                            {
                                                waitingList?.waiting?.type == "call" ?
                                                    "Reject Call"
                                                    : waitingList?.waiting?.type == "chat"
                                                        ? "Reject Chat"
                                                        : null
                                            }
                                        </button>
                                    </div>
                            }
                        </Col>
                        <Col lg={12} className={`d-flex flex-wrap ${showConnect || chatConnect ? "flex-column gap-5" : null} justify-content-between mt-3`}>
                            <Col lg={5} className='waiting-user-detail waiting-user-top'>
                                <Col lg={8} sm={8} className='d-flex flex-column gap-4'>
                                    <h4>User Profile Details </h4>
                                    <Col lg={12} className='d-flex flex-wrap gap-4'>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Name</h5>
                                            <span>{waitingList?.waiting?.subuser?.name}</span>
                                        </Col>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Date of Birth</h5>
                                            <span>{waitingList?.waiting?.subuser?.dateofbirth}</span>
                                        </Col>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Gender</h5>
                                            <span>{waitingList?.waiting?.subuser?.gender}</span>
                                        </Col>
                                        <Col lg={5}>
                                            <h5 className="f-w-500">Marital status</h5>
                                            <span>{waitingList?.waiting?.subuser?.maritalstatus}</span>
                                        </Col>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Birth Time</h5>
                                            <span>{waitingList?.waiting?.subuser?.timeofbirth}</span>
                                        </Col>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Birth Place</h5>
                                            <span>{waitingList?.waiting?.subuser?.placeofbirth}</span>
                                        </Col>
                                    </Col>
                                    <h4>Order Details </h4>
                                    <Col lg={12} className='d-flex flex-wrap gap-4'>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Order Id</h5>
                                            <span>{waitingList?.waiting?.orderId}</span>
                                        </Col>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Duration</h5>
                                            <span>{Math.floor(waitingList?.waiting?.duration / 60)} Min</span>
                                        </Col>
                                        <Col lg={5}>
                                            <h5 className="f-w-500"> Total Rate</h5>
                                            <span>₹ {waitingList?.waiting?.totalrate}</span>
                                        </Col>
                                    </Col>
                                </Col>
                                <Col lg={4} sm={4}>
                                    <div className='d-flex justify-content-center user-profile'>
                                        <img src={waitingList?.profile ? waitingList?.profile?.file : EmptyProfile} alt="img-fluid" />
                                    </div>
                                </Col>
                            </Col>
                            <Col lg={6} className='waiting-user-detail birth-chart'>
                                <Tabs
                                    defaultActiveKey="birthchart"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="birthchart" title="Birth Chart">
                                        <BirthChart list={waitingList} />
                                    </Tab>
                                    <Tab eventKey="planets" title="Planets">
                                        <PlanetDetails list={waitingList} />
                                    </Tab>
                                    <Tab eventKey="dashaperiod" title="Dasha Period">
                                        <DashaPeriodDetails list={waitingList} />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Col>
                    </Row>
                    :
                    <>
                        <div className='no-data pt-5'>
                            <p>No sufficient data available!</p>
                        </div>
                    </>
            }
        </>
    )
}

export default WaitListDetails;