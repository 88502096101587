import {configureStore} from '@reduxjs/toolkit'
import ReduxSlice from './Slice'


 const Store = configureStore({
    reducer:{
       Astromind:ReduxSlice
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
       serializableCheck: false,
    }),
})

export default Store;
